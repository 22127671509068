import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, FilterContainer, ContainerOptions, CreateForm, FormConsumer, TitleTax } from "./styles";

import { FormattedMessage, injectIntl } from "react-intl";

import {
  FiSave,
  FiPlus,
  FiArrowLeft,
  FiXCircle,
} from "react-icons/fi";

import api from "../../../../services/api";

import ButtonIcon from "../../../../components/ButtonIcon";
import ButtonImage from "./../../../../components/ButtonImage";
import iconVoltar from "./../../../../assets/images/icone-voltar.svg";

import InputLabel from "../../../../components/InputLabel";
import InputMaskDefault from "../../../../components/InputMaskDefault";
import ContentSpinner from "../../../../components/Spinner/Content";
import AuthContext from "../../../../context/auth";
import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";
import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";

const ListPricesFuels = ({ intl }) => {
  const [showFilter, setShowFilter] = useState(false);

  const { user } = useContext(AuthContext);
  const [errors, setErrors] = useState();

  const [loadingList, setLoadingList] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const [distribuidor, setDistribuidor] = useState(() => {
    if (user.cadastro_tipo_id === 1) {
      return;
    } else {
      return {
        value: user.cadastro_id,
        label: user.razao_social,
      };
    }
  });
  const [baseDistribuicao, setBaseDistribuicao] = useState("");
  const [produto, setProduto] = useState("");

  useEffect(() => {(async () => {
    setLoadingList(false);
    if (user.cadastro_tipo_id === 2){
      loadDistribuidor(user.cadastro_id)
    } else {
      setLoadingList(false);
    }

  })();
  }, []);

  const handleCreate = useCallback(() => {
    history.push("/orders/price-freight/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const loadDistribuidor = useCallback(async(id) => {
    setLoadingList(true);

    try {
      const { data: response } = await api.get(`/cadastro/${id}`);

      /** aba base Distribuicao */
      if (response?.base_distribuicao) {
        // const formattedBases = response?.base_distribuicao.map((base) => ({
        //   label: base.nome,
        //   value: base.id,
        //   municipio: {
        //     municipio_id: base.municipio.id,
        //     nome: base.municipio.nome,
        //     valor_frete: "",
        //     vigencia_ate: "",
        //   },
        // }));

        // formattedBases.map((base) => {
        //   response?.fretes.map((frete) => {
        //     if (frete.base_distribuicao.id === base.value){
        //       base.municipio.valor_frete = frete.valor_frete.replace(".", ",");
        //       base.municipio.vigencia_ate = frete.vigencia_ate;
        //     }
        //   })
        //   return base
        // })

        var idsBases = []
        response?.base_distribuicao.map((base) => {
          idsBases.push(base.id)
        })

        var dataMunicipio = {
          cadastro_id: id,
          basesdistribuicao_ids: idsBases
        }
        const { data: municipios } = await api.post("/frete/municipios-cadastro-base", dataMunicipio);

        const formattedBases = response?.base_distribuicao.map((base) => ({
          label: base.nome,
          value: base.id,
          municipio: [],
        }))

        formattedBases.map((base) => {
          municipios?.bases.map((frete) => {
            if (frete.basedistribuicao_id === base.value){
              base.municipio.push({
                base_distribuicao_id: frete?.basedistribuicao_id,
                id: frete.municipio?.id,
                nome: frete.municipio?.nome,
                cadastro_id: id,
                valor_frete: frete.municipio?.fretes[0]?.valor_frete?.replace(".", ","),
                vigencia_ate: frete.municipio?.fretes[0]?.vigencia_ate,
              })
            }
          })
          return base
        });

        setBaseDistribuicao(formattedBases);
        setLoadingList(false)
      }

    } catch (error) {
      setLoadingList(false)
    }
  }, []);

  const handleChangeInputFrete = useCallback(
    (e, indexBase, indexMunicipio) => {
      baseDistribuicao[indexBase].municipio[indexMunicipio].valor_frete = e.target.value;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleChangeInputVigencia = useCallback(
    (e, indexBase, indexMunicipio) => {
      baseDistribuicao[indexBase].municipio[indexMunicipio].vigencia_ate = e.target.value;

      setBaseDistribuicao([...baseDistribuicao]);
    },
    [baseDistribuicao]
  );

  const handleUpdate = useCallback(async () => {
    setLoadingButton(true);
    var  freteFormmated = [];
    baseDistribuicao.map((base) => {
      if (base.municipio.length !== 0){
        base.municipio.map((municipio)=> {
          freteFormmated.push(
            {
              base_distribuicao_id: base?.value,
              municipio_id: municipio.id,
              vigencia_ate: municipio?.vigencia_ate,
              valor_frete: municipio?.valor_frete ? parseFloat(municipio?.valor_frete
                .replace(".", "")
                .replace(",", ".")) : "",
              cadastro_id: distribuidor?.value
            }
          )
        })
      }
    });

    var dataMunicipio = {
      fretes: freteFormmated
    }

    try {
      await api.post('/frete/store', dataMunicipio);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
      setErrors("");
      setLoadingButton(false);

    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroEditarRegistro" />);
    }

  }, [baseDistribuicao, distribuidor]);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="valoresFretes" />
            </h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#221c46"}
              onClick={handleCreate}
              placeholder={<FormattedMessage id="incluir" />}
              permission={
                user.cadastro_tipo_id === 1
                  ? "preco-importar[1]"
                  : "preco-importar[2]"
              }
            />
            <ButtonIcon
              Icon={FiSave}
              backgroundColor={"#4c84ec"}
              onClick={handleUpdate}
              placeholder={<FormattedMessage id="salvar" />}
              permission={true}
              loading={loadingButton}
            />

          </div>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <form>
              {user.cadastro_tipo_id === 1 && (
                <AsyncListSelect
                  value={distribuidor}
                  labelPlaceholder={<FormattedMessage id="distribuidores" />}
                  placeholder={<FormattedMessage id="selecione" />}
                  onChange={(e) => {
                    setDistribuidor(e);
                    loadDistribuidor(e.value);
                  }}
                  myOptions={"distribuidores"}
                  cacheOptions
                  defaultOptions
                  isClearable
                />
              )}
            </form>

            <CreateForm>
              <header>
                <p>
                  <FormattedMessage id="distribuidores" />: {distribuidor?.label}
                </p>
              </header>

              {baseDistribuicao === "" ? (
                 <h1>
                  <FormattedMessage id="selecioneDistribuidor" />
                 </h1>
              ) : (
              <ContainerOptions>
                <div>
                  <main>
                  </main>
                  <FormConsumer>
                    <span>
                    <strong>
                        <TitleTax>
                          <FormattedMessage id="baseDistribuicao" />
                        </TitleTax>
                      </strong>
                    <strong>
                        <TitleTax>
                          <FormattedMessage id="cidade" />
                        </TitleTax>
                      </strong>
                      <strong>
                        <TitleTax>
                          <FormattedMessage id="valorFrete" />
                        </TitleTax>
                      </strong>
                      <strong>
                        <TitleTax>
                          <FormattedMessage id="dataVigencia" />
                        </TitleTax>
                      </strong>
                    </span>
                  </FormConsumer>

                  {baseDistribuicao.map((base, indexBase) => (
                   <FormConsumer key={base.value}>
                    {base.municipio.map((municipio, indexMunicipio) => (
                      <ul>
                        <div >
                          <li>
                            <p>{base.label}</p>
                          </li>
                          <li>
                            <p>{municipio?.nome}</p>
                          </li>
                          <li>
                             <InputMaskDefault
                               value={municipio?.valor_frete}
                               onChange={(e) => {
                                 return handleChangeInputFrete(e, indexBase, indexMunicipio);
                               }}
                               mask="currency4"
                               geterrorcolorbackground={
                                errors?.[`${indexMunicipio}.valor_frete`]
                              }
                             />
                          </li>
                          <li>
                             <InputLabel
                               type="date"
                               style={{ color: "hsl(0,0%,50%)" }}
                               value={municipio?.vigencia_ate}
                               onChange={(e) => {
                                 return handleChangeInputVigencia(e, indexBase, indexMunicipio);
                               }}
                               geterrorcolorbackground={
                                errors?.[`${indexMunicipio}.vigencia_ate`]
                              }
                             />
                          </li>
                        </div>
                      </ul>
                    ))}

                   </FormConsumer>
                  ))}

                </div>
               </ContainerOptions>
              )}

            </CreateForm>
            <hr/>
          </>
        )}
      </Container>

      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <AsyncListSelect
              value={produto}
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setProduto(e);
              }}
              myOptions={"produtos"}
              cacheOptions
              defaultOptions
            />

            <AsyncListSelect
              value={baseDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={<FormattedMessage id="todos" />}
              onChange={(e) => {
                setBaseDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />
          </main>

          <footer>
            {/* <Button
              type="button"
              loading={loadingButton}
              onClick={handleFilter}
            >
              <FormattedMessage id="filtrar" />
            </Button> */}
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListPricesFuels);
