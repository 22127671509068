import styled, { keyframes } from "styled-components";
import { lighten, shade } from "polished";

const buttonThemes = {
  default: "#931a28",
  green: "#22bd41",
  blue: "#3b89ad",
  back: "#666",
};

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }


`;


const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  .sideBySide {
    width: 50%;
    float: left;
    padding: 5px;
  }

  .sideBySideTwo {
    display: inline-block;
    width: 50%;
    padding: 5px;
  }
`;

export const CreateForm = styled.div`
  width: 100%;
  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }
`;

export const ContainerOptions = styled.div`
  main {
    display: grid;
    gap: 1%;
    margin-top: 10px;
    grid-template-columns: 24.5% 24% 24.5% 24%;
  }

  > div {
    width: 100%;
    /* height: 35px; */
    background-color: #221c46;
    color: #fff;
    margin: 10px 0 10px 0;
    display: flex;
    align-items: center;

    span{
      width: 14%;
    }

    li {

      height: 100%;
      width: 27%;
      text-align: center;
      margin-bottom: 1px solid;

      p {
        font-weight: 700;
        margin: 10px 0 10px 0;
      }
    }
  }

  > ul {
    div {
      display: flex;
      align-items: center;
      margin: 0;
      border-bottom: 1px solid #c5c5c5;
      justify-content: center;

      input {
        width: 10%;
      }

      li {
        height: 100%;
        width: 27%;
        padding: 0 5px;
        text-align: center;
        margin-top: 5px;

        p {
          font-weight: 500;
          margin-top: 10px;
        }

      }
    }
  }
`;

export const Costumer = styled.div`
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  > meta {
    strong {
      margin-right: 6.5%;
    }

    strong {
      :first-child {
        margin-left: 4.5%;
      }
    }
  }

  > ul {
    div {
      display: flex;
      align-items: center;
      margin: 0;

      li {
        height: 100%;
        width: 20%;
        padding: 0 5px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
`;

export const ButtonSelect = styled.button`
  width: 100% !important;
  height: 38px !important;
  background-color: #221c46;
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  outline: none;
  margin-bottom: 5px auto;
  margin-top: 18px;

  transition: all 0.4s;

  :hover {
    background-color: ${shade(0.3, "#221c46")};
  }

  :active {
    outline: none;
    background-color: ${lighten(0.3, "#221c46")} !important;
    box-shadow: none;
  }

  :focus {
    outline: none;
  }

  svg {
    height: 18px;
    width: 18px;
    animation: ${rotate} 2s linear infinite;
    color: #fff;
  }
`;


export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;
