import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { Container, CreateForm, CreateFooter } from "./styles";

import api from "./../../../services/api";
import InputLabel from "./../../../components/InputLabel";
import InputMaskDefault from "./../../../components/InputMaskDefault";

import Button from "./../../../components/ButtonDefault";

import ListSelect from "./../../../components/ListSelect";
import CreatableListSelect from "./../../../components/ListSelect/CreatableListSelect";

import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

export const EditProduct = ({ intl }) => {
  const [id, setId] = useState(0);
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ncm, setNcm] = useState([]);
  const [ncmInputValue, setNcmInputValue] = useState("");

  const [status, setStatus] = useState();
  const [errors, setErrors] = useState(null);

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const codigoPlaceholder = intl.formatMessage({ id: "codigo" });
  const descricaoPlaceholder = intl.formatMessage({ id: "descricao" });
  const digiteNCMPlaceholder = intl.formatMessage({ id: "digiteNcM" });

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/register/sodexo/products-fuels/list");
    }
    setId(history.location.state?.id);
    setCodigo(history.location.state?.codigo);
    setDescricao(history.location.state?.descricao);

    if (history.location.state?.ncm) {
      const formattedNCM = history.location.state?.ncm.map((n) => ({
        label: n.codigo,
        value: n.codigo,
      }));

      setNcm(formattedNCM);
    }

    setStatus(
      history.location.state?.status === 1
        ? { value: 1, label: <FormattedMessage id="ativo" /> }
        : { value: 0, label: <FormattedMessage id="inativo" /> }
    );
  }, [history]);

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleEdit = useCallback(async () => {
    setLoadingButton(true);
    const ncmArray = ncm?.map((n) => n.value);

    const data = {
      codigo,
      descricao,
      ncm: ncmArray,
      ativo: status.value,
    };

    try {
      await api.put(`/produto/${id}`, data);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
      setLoadingButton(false);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      toast.error(<FormattedMessage id="erroEditarRegistro" />);
      setLoadingButton(false);
    }
  }, [id, codigo, descricao, ncm, status, history]);

  const createOptionNCM = useCallback(
    (label) => ({
      label,
      value: label,
    }),
    []
  );

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            {" "}
            Editar Combustível
          </h4>
        </header>

        <form>
          <InputMaskDefault
            name="codigo"
            type="text"
            label={codigoPlaceholder + " *"}
            mask={"int5"}
            value={codigo}
            onChange={(e) => setCodigo(e.target.value.replace(/[^\d]+/g, ""))}
            geterror={errors?.codigo}
          />
          <InputLabel
            name="descricao"
            type="text"
            maxLength={50}
            label={descricaoPlaceholder + " *"}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />

          <CreatableListSelect
            isMulti
            //formatCreateLabel={() => "Criar NCM"}
            labelPlaceholder="NCM * - Pressione Enter para inserir (8 digitos)"
            placeholder={digiteNCMPlaceholder}
            inputValue={ncmInputValue}
            onChange={(newValue, actionMeta) => {
              setNcm(newValue);
            }}
            onInputChange={(inputValue, actionMeta) => {
              setNcmInputValue(inputValue.replace(/[^\d]+/g, ""));
            }}
            onKeyDown={(inputValue) => {
              try {
                if (inputValue.keyCode === 32 || inputValue.keyCode === 13) {
                  if (ncmInputValue.replace(" ", "").length === 8) {
                    setNcm([...ncm, createOptionNCM(ncmInputValue)]);
                    setNcmInputValue("");
                  }
                }
              } catch (error) {
                setNcm([createOptionNCM(ncmInputValue)]);
                setNcmInputValue("");
              }
            }}
            geterror={errors?.["ncm.0"]}
            value={ncm}
            menuIsOpen={false}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder="Status"
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleEdit} loading={loadingButton}>
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditProduct);
