import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { Container, CreateForm, CreateFooter } from "./styles";

import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";
import api from "./../../../../services/api";
import InputLabel from "./../../../../components/InputLabel";
import Button from "./../../../../components/ButtonDefault";

import ListSelect from "./../../../../components/ListSelect";

export const EditConsultantClassification = ({ intl }) => {
  const [id, setId] = useState("");
  const [tipo, setTipo] = useState("");
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [codigoExterno, setCodigoExterno] = useState("");
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState();

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];


  const tipoOptions = [
    { value: "Farmer", label:"Farmer" },
    { value: "Hunter", label:"Hunter" },
    { value: "Merchant", label:"Merchant" },
  ];

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push(
        "/register/sodexo/tables/consultants-classification/list"
      );
    }
    setId(history.location.state?.id);
    setCodigo(history.location.state?.codigo);
    setDescricao(history.location.state?.descricao);
    setCodigoExterno(history.location.state?.codigo_externo);
    setTipo({value: history.location.state?.tipo, label: history.location.state?.tipo});
    setStatus(
      history.location.state?.status === 1
        ? { value: 1, label: <FormattedMessage id="ativo" /> }
        : { value: 0, label: <FormattedMessage id="inativo" /> }
    );
  }, [history]);

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  const handleEdit = useCallback(async () => {
    setLoadingButton(true);
    const data = {
      codigo,
      tipo: tipo.value,
      descricao,
      codigo_externo: codigoExterno,
      ativo: status.value,
    };

    try {
      await api.put(`/tabela-consultor/${id}`, data);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);

      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      toast.error(<FormattedMessage id="erroEditarRegistro" />);
    }
  }, [codigo, descricao, codigoExterno, status, id, history, tipo]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);


  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="editarConsultor" />
          </h4>
        </header>

        <form>
          {/* <InputMaskDefault
            name="codigo"
            type="text"
            label={<FormattedMessage id="codigo" />}
            mask={"int5"}
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            geterror={errors?.codigo}
          /> */}
          <InputLabel
            name="descricao"
            type="text"
            label={<FormattedMessage id="consultor" />}
            maxLength={40}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />
          <ListSelect
            onChange={(e) => setTipo(e)}
            labelPlaceholder={<FormattedMessage id="tipo" />}
            placeholder={<FormattedMessage id="selecionar" />}
            options={tipoOptions}
            value={tipo}
            geterror={errors?.tipo}
          />

          <InputLabel
            name="codigo_externo"
            type="text"
            label={<FormattedMessage id="codigoExterno" />}
            maxLength={10}
            value={codigoExterno}
            onChange={(e) => setCodigoExterno(e.target.value)}
            geterror={errors?.codigo_externo}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder={statusPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleEdit} loading={loadingButton}>
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditConsultantClassification);
