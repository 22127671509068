import styled, { css } from "styled-components";
import MaskedInput from "react-text-mask";

export const Container = styled.div`
  margin-bottom: 10px;
  width: 100%;
  position: relative;

  p {
    margin-bottom: 0px !important;
    padding-left: 10px;
    font-weight: bold;
    color: #221c46;
  }

  /* span {
    background-color: transparent;
    font-size: 13px;
    font-weight: 500;
    font-family: sans-serif;
    color: #808080;

    margin-left: 12px;
    margin-top: 2px;
    transition: all 0.2s ease;
    white-space: nowrap;

    position: absolute;
  } */

  /* ${(props) =>
    props.IsFilled &&
    css`
      & {
        height: 50px;
      }
      & span {
        position: relative;
        transform: translateY(-25px);
      }
    `} */
`;

export const Header = styled.div`
  display: flex;
  //padding: 3px;

  > span {
    margin-left: 10px;
  }
`;

export const Input = styled(MaskedInput)`
  background: ${(props) => (props.readOnly ? "#D1CFD7" : "#fff")};
  border: solid 1px #d9e0e7;
  border-color: ${(props) =>
    props.geterrorcolorbackground ? "#e8617a" : "#d9e0e7"};
  color: ${(props) => (props.readOnly ? "#908C99" : "#221c46")};
  font-size: ${(props) => (props.type === "file" ? "12px" : "14px")};
  font-weight: 500;

  width: 100% !important;

  height: 38px !important;

  /* border-radius: 5px; */
  padding: 10px;

  outline: 0;

  :focus {
    border: 2px solid #221c46;
  }

  ::-webkit-file-upload-button {
    background: #221c46;
    color: #f8f8f8;
    height: 20px;
    font-size: 12px;
    /* border-radius: 5px; */
    border: 1px solid #999;
    vertical-align: center;
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
  }
`;

export const MessageError = styled.div`
  width: 100%;
  height: 100%;
  display: block;

  align-items: center;
  height: 10px !important;
  transition: all 0.7s;
  margin-top: 5px;

  p {
    width: max-content;
    height: 100%;
    font-size: 11px;
    color: #B30000;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    width: max-content;
  }
`;
