import React, { forwardRef } from "react";

import { Container, Input, MessageError, Header } from "./styles";

const InputDefault = forwardRef((props, ref) => {

  const { label,
    placeholder,
    readOnly,
    value,
    defaultValue,
    onChange,
    geterror,
    type,
    required,
    toolTip,
    ...rest} = props

  return (
    <>
      <Container readOnly={readOnly}>
        <Header>
          <p>{label} {required && <span style={{color: "#B30000"}}>*</span>}</p>
          {toolTip && <span>{toolTip}</span>}
        </Header>

        <Input
          ref={ref}
          {...props}
          {...rest}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          defaultValue={defaultValue}
          alwaysShowMask={false}
          placeholder={placeholder}
          type={type}
        />
        {geterror && (
          <MessageError>
            <div>
              {Array.isArray(geterror) ? (
                <p >{geterror}</p>
              ) : (
                <p>{geterror}</p>
              )}
            </div>
          </MessageError>
        )}
        {/* {geterror && isErrored ? (
          <Error>
            <p onClick={handleFocus}>{geterror}</p>
          </Error>
        ) : null} */}
      </Container>
    </>
  );
});

export default InputDefault;
