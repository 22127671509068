import React, { useState, useEffect, useContext, useCallback } from "react";
import api from "../../services/api.js";
import { PageSettings } from "./../../config/page-settings.js";
import { Container, EmpresaContainer, List } from "./sidebar-profile-styles";
import { FormattedMessage } from "react-intl";
// import swal from "sweetalert";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import AuthContext from "./../../context/auth";

const SidebarProfile = ({ intl }) => {
  const [userName, setUserName] = useState("Trade");
  const [fantasia, setFantasia] = useState("");
  const [nivel, setNivel] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [cadastroId, setCadastroId] = useState("");
  // const [empresaLogadaId, setEmpresaLogadaId] = useState("");
  // const [empresaLogadaCNPJ, setEmpresaLogadaCNPJ] = useState("");
  const [empresaSelected, setEmpresaSelected] = useState("");

  const { version, user } = useContext(AuthContext);

  const history = useHistory();

  // const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  // const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  // const cancelar = intl.formatMessage({ id: "cancelar" });

  useEffect(() => {
    const loadUserToken = localStorage.getItem("@wizeoTrade:token");

    if (loadUserToken) {
      const token = JSON.parse(loadUserToken);
      api.defaults.headers.Authorization = `Bearer ${token}`;
      // setUser(JSON.parse(loadUser));
    }

    async function getUser() {
      try {
        const { data: response } = await api.get("/usuario/get-data-user");

        const infoUser = {
          email: response.usuario.email,
          nome: response.usuario.name,
          user_id: response.usuario.id,
          cadastro_id: response?.cadastro[0].id,
          cadastro_tipo_id: response?.cadastro[0]?.cadastro_tipo_id,
          fantasia: response.cadastro[0].fantasia,
          tipo_id:
            response.usuario.tipo_id === 1 ? (
              "Master"
            ) : (
              <FormattedMessage id="nivelComum" />
            ),
        };

        const { data: responseEmpresas } = await api.get(
          `usuario/filter-user-cadastro`,
          {
            params: {
              email: response.usuario.email,
              cadastro_tipo_id: response?.cadastro[0]?.cadastro_tipo_id,
              paginate: 500
            },
          }
        );

        // setEmpresaLogadaId(response?.cadastro[0].id);
        // setEmpresaLogadaCNPJ(response?.cadastro[0].cpf_cnpj);

        var empresasFormatted = responseEmpresas.data.map((empresa) => {
          const objeto = {
            value: empresa.cadastro_id,
            label: empresa.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ),
          };

          return objeto;
        });

        setEmpresaSelected({value: response?.cadastro[0].id, label: response?.cadastro[0].cpf_cnpj.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        )});
        setEmpresas(empresasFormatted);
        setUserName(infoUser.nome);
        setFantasia(infoUser.fantasia);
        setNivel(infoUser.tipo_id);
        setCadastroId(
          infoUser?.cadastro_tipo_id === 1
            ? "Pluxee"
            : infoUser?.cadastro_tipo_id === 2
            ? "Distribuidor/TRR"
            : "Cliente"
        );
      } catch (error) {
        console.log(error);
        localStorage.removeItem("@wizeoTrade:token");
      }
    }

    getUser();
  }, []);

  const handleChangeEmpresa = useCallback(
    async (e) => {

      try {
        await api.post("usuario/mudar-empresa-logada", {
          cadastro_id: e.value,
        });

        history.push("/home");
        history.go(0);

      } catch (error) {}
    },
    [history]
  );

  return (
    <PageSettings.Consumer>
      {() => (
        <ul className="nav" >
          <li className={"nav-profile"}>
            <Container>
              <h4>{userName}</h4>
              <small>
                {cadastroId} {nivel}
              </small>
              <p>{fantasia}</p>
              <small>{version}</small>

              {user?.cadastro_tipo_id !== 1 && (
                <EmpresaContainer>
                  <small>Empresa</small>

                  <List
                    options={empresas}
                    value={empresaSelected}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    classNamePrefix='filter'
                    onChange={(e) => handleChangeEmpresa(e)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      border: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#FcF1F0 !important",
                        primary25: '#FF7375',
                        primary: '#221c46',
                      },
                    })}

                  />


                  {/* <select
                //     name="empresa"
                //     id="empresa"
                //     onChange={(e) => handleChangeEmpresa(e)}
                //   >
                //     {empresas.map((item) => {
                //       return (
                //         <option
                //           value={item.cadastro_id}
                //           selected={
                //             parseInt(item.cadastro_id) ===
                //             parseInt(empresaSelected)
                //               ? true
                //               : false
                //           }
                //         >
                //           {item.cpf_cnpj.replace(
                //             /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                //             "$1.$2.$3/$4-$5"
                //           )}
                //         </option>
                //       );
                //     })}
                //   </select> */}
                </EmpresaContainer>
              )}
            </Container>
          </li>
        </ul>
      )}
    </PageSettings.Consumer>
  );
};

export default injectIntl(SidebarProfile);
