import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { Container, CreateForm, CreateFooter } from "./styles";

import api from "../../../../services/api";
import InputLabel from "../../../../components/InputLabel";
import InputMaskDefault from "../../../../components/InputMaskDefault";

import Button from "../../../../components/ButtonDefault";

import ListSelect from "../../../../components/ListSelect";
import CreatableListSelect from "../../../../components/ListSelect/CreatableListSelect";

import { getErrorsApiValidation } from "../../../../utils/getErrorsValidation";

export const EditBrand = ({ intl }) => {
  const [id, setId] = useState(0);
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [codigoExterno, setCodigoExterno] = useState("");

  const [status, setStatus] = useState();
  const [errors, setErrors] = useState(null);

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const nomePlaceholder = intl.formatMessage({ id: "nome" });
  const descricaoPlaceholder = intl.formatMessage({ id: "descricao" });

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/register/sodexo/tables/brands/list");
    }
    setId(history.location.state?.id);
    setNome(history.location.state?.nome);
    setDescricao(history.location.state?.descricao);
    setCodigoExterno(history.location.state?.codigo_externo);

    setStatus(
      history.location.state?.status === 1
        ? { value: 1, label: <FormattedMessage id="ativo" /> }
        : { value: 0, label: <FormattedMessage id="inativo" /> }
    );
  }, [history]);

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleEdit = useCallback(async () => {
    setLoadingButton(true);

    const data = {
      nome,
      descricao,
      codigo_externo: codigoExterno,
      ativo: status.value,
    };

    try {
      await api.put(`/produto-marca/${id}`, data);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
      setLoadingButton(false);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      toast.error(<FormattedMessage id="erroEditarRegistro" />);
      setLoadingButton(false);
    }
  }, [id, nome, descricao, status, history, codigoExterno]);


  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            {" "}
            <FormattedMessage id="editarMarca" />
          </h4>
        </header>

        <form>

        <InputMaskDefault
            name="codigo_externo"
            type="text"
            mask={"int5"}
            label={<FormattedMessage id="codigoExterno" />}
            value={codigoExterno}
            maxLength={10}
            onChange={(e) => setCodigoExterno(e.target.value)}
            geterror={errors?.codigo_externo}
          />

          <InputLabel
            name="nome"
            type="text"
            label={nomePlaceholder + " *"}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            geterror={errors?.nome}
          />
          <InputLabel
            name="descricao"
            type="text"
            maxLength={50}
            label={descricaoPlaceholder + " *"}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder="Status"
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleEdit} loading={loadingButton}>
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditBrand);
