import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import ListSelect from "./../../../components/ListSelect";
import CreatableListSelect from "./../../../components/ListSelect/CreatableListSelect";

import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";
import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import InputLabel from "./../../../components/InputLabel";
import InputMaskDefault from "./../../../components/InputMaskDefault";
import Button from "./../../../components/ButtonDefault";

export const CreateProduct = ({ intl }) => {
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ncm, setNcm] = useState([]);
  const [ncmInputValue, setNcmInputValue] = useState("");

  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState(null);

  const history = useHistory();
  const codigoPlaceholder = intl.formatMessage({ id: "codigo" });
  const descricaoPlaceholder = intl.formatMessage({ id: "descricao" });
  const digiteNCMPlaceholder = intl.formatMessage({ id: "digiteNcM" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);
    const ncmArray = ncm?.map((n) => n.value);

    const data = {
      codigo,
      descricao,
      ncm: ncmArray,
      ativo: status.value,
    };

    try {
      await api.post("/produto", data);
      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
      setCodigo("");
      setDescricao("");
      setNcm([]);
      setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
      setErrors(null);
      setLoadingButton(false);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);

      toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }
  }, [codigo, descricao, ncm, status]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const createOptionNCM = useCallback(
    (label) => ({
      label,
      value: label,
    }),
    []
  );
  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            Cadastrar Combustível
          </h4>
        </header>

        <form>
          <InputMaskDefault
            name="codigo"
            type="text"
            label={codigoPlaceholder + " *"}
            mask={"int5"}
            value={codigo}
            onChange={(e) => setCodigo(e.target.value.replace(/[^\d]+/g, ""))}
            geterror={errors?.codigo}
          />

          <InputLabel
            name="descricao"
            type="text"
            label={descricaoPlaceholder + " *"}
            maxLength={50}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />

          <CreatableListSelect
            isMulti
            // formatCreateLabel={() => "Criar NCM"}
            placeholder={digiteNCMPlaceholder}
            labelPlaceholder="NCM * - Pressione Enter para inserir (8 digitos)"
            inputValue={ncmInputValue}
            onChange={(newValue, actionMeta) => {
              setNcm(newValue);
            }}
            onInputChange={(inputValue, actionMeta) => {
              setNcmInputValue(inputValue.replace(/[^\d]+/g, ""));
            }}
            onKeyDown={(inputValue) => {
              try {
                if (inputValue.keyCode === 32 || inputValue.keyCode === 13) {
                  if (ncmInputValue.replace(" ", "").length === 8) {
                    setNcm([...ncm, createOptionNCM(ncmInputValue)]);
                    setNcmInputValue("");
                  }
                }
              } catch (error) {
                setNcm([createOptionNCM(ncmInputValue)]);
                setNcmInputValue("");
              }
            }}
            value={ncm}
            geterror={errors?.["ncm.0"]}
            menuIsOpen={false}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder="Status"
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateProduct);
