import React, { useContext } from "react";

import { Container, ToolTip, LoadingIcon } from "./styles";

import AuthContext from "./../../context/auth";

const ButtonIcon = ({
  Icon,
  placeholder,
  geterror,
  permission,
  size,
  loading = false,
  radius,
  disabled,
  ...rest
}) => {
  const { user } = useContext(AuthContext);

  const checkPermission = user.permission.filter(
    (p) => p.namePermission === permission
  );

  if (permission) {
    if (user.tipo_id === 2 && checkPermission.length === 0) {
      return <></>;
    }
  }

  return (
    <>
      <Container size={size} {...rest} disabled={loading || disabled} radius={radius}>
        {placeholder && <ToolTip>{placeholder}</ToolTip>}
        {loading ? <LoadingIcon /> : <Icon />}
      </Container>
    </>
  );
};

export default ButtonIcon;
