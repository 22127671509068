import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { registerLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";

import ListSelect from "./../../../../components/ListSelect";
import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";
import { parseISO, format, addDays, compareAsc } from "date-fns";
import MessageDefault from "./../../../../components/MessageDefault";

import api from "./../../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  InfoOrder,
  FormTax,
  FormProducts,
  TitleTax,
  LoadingPrazos,
  HeaderOrder,
  ProgramOrderContainer,
  DatePickerComponent,
  FooterDatePicker,
  InfoMotoristaContainer
} from "./styles";
import moment from "moment";
import Alert from "./../../../../assets/images/alert-vermelho.png";

import MaskInput from "./../../../../config/input-mask";
import Button from "./../../../../components/ButtonDefault";
import TextArea from "./../../../../components/TextAreaLabel";
import LoadingContent from "./../../../../components/Spinner/Content";
import { getErrorsApiValidation } from "./../../../../utils/getErrorsValidation";

import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

import AuthContext from "./../../../../context/auth";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import ButtonIcon from "./../../../../components/ButtonIcon";
import { FiFileText } from "react-icons/fi";
import { FiXCircle } from "react-icons/fi";

export const CreateOrders = ({ intl }) => {
  const [showContent, setShowContent] = useState(true);
  const [cotacao] = useState("Cotação");
  const [saldo, setSaldo] = useState("");
  const [limiteCredito, setLimiteCredito] = useState("");
  const [tolerancia, setTolerancia] = useState("");

  const [operadorId, setOperadorId] = useState("");
  const [cliente, setCliente] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [usuarios, setUsuarios] = useState("");
  const [listaUsuarios, setListaUsuarios] = useState("");
  const [pedidoSistema, setPedidoSistema] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [date, setDate] = useState("");
  const [frete, setFrete] = useState("");
  const [abastecimento, setAbastecimento] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [horario, setHorario] = useState("");
  // const [prazo, setPrazo] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const [serverDate, setServerDate] = useState();
  const [dataProgramacao, setDataProgramacao] = useState([{ date: undefined }]);
  const [todosPedidosProgramados, setTodosPedidosProgramados] = useState([]);
  const [excludeDatesDatePicker, setExcludeDatesDatePicker] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const [arrayPrazo, setArrayPrazo] = useState([
    {
      prazo: "",
    },
  ]);

  const [hasPrazo, setHasPrazo] = useState(true);

  const [listaProdutos, setListaProdutos] = useState([]);

  const [listagemProdutos, setListagemProdutos] = useState([]);
  const [produtoSelected, setProdutoSelected] = useState([]);
  const [menorPrecoSelected, setMenorPrecoSelected] = useState("");
  const [quantidadeProduto, setQuantidadeProduto] = useState("");
  const [addProdutoButton, setAddProdutoButton] = useState(false);


  // const [idCadastro, setIdCadastro] = useState([]);

  const [checkUser, setCheckUser] = useState(false);

  const [showScreen, setShowScreen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState();

  const [showInfoMotorista, setShowInfoMotorista] = useState(false);
  const [nomeMotorista, setMotorista] = useState("");
  const [cpfMotorista, setCpfMotorista] = useState("");
  const [RgMotorista, setRgMotorista] = useState("");
  const [placaVeiculo, setPlacaVeiculo] = useState("");
  const [celular, setCelular] = useState("");

  const history = useHistory();
  const { user } = useContext(AuthContext);
  //const { language } = useContext(Language);

  const prazoRef = useRef(null);
  const produtoRef = useRef(null);

  const freteOptions = [
    { value: "CIF (Entrega)", label: "CIF (Entrega)" },
    { value: "FOB (Retira)", label: "FOB (Retira)" },
  ];

  const abastecimentoOptions = [
    { value: "Bomba", label: "Com Bomba" },
    { value: "Descarga", label: "Sem Bomba" },
  ];

  const periodoOptions = [
    { value: "Manhã", label: "Manhã" },
    { value: "Tarde", label: "Tarde" },
    { value: "Noite", label: "Noite" },
  ];

  const clientePlaceholder = intl.formatMessage({ id: "cliente" });
  const usuarioPlaceholder = intl.formatMessage({ id: "usuario" });
  const dataEntregaDesejadaPlaceholder = intl.formatMessage({
    id: "dataEntregaDesejada",
  });
  const fretePlaceholder = intl.formatMessage({ id: "frete" });
  const modoAbastecimentoPlaceholder = intl.formatMessage({
    id: "modoAbastecimento",
  });
  const periodoDesejadoPlaceholder = intl.formatMessage({
    id: "periodoDesejado",
  });
  const horarioDesejadoPlaceholder = intl.formatMessage({
    id: "horarioDesejado",
  });

  const handleBackButton = useCallback(() => {
    history.push("/orders/painel");
    // history.goBack();

    if ( user.cadastro_tipo_id === 1){
      history.push("/orders/painel/v2");

    } else {
      history.push("/orders/painel");
    }
  }, [history]);

  registerLocale("pt", ptBr);

  const [highlightDaPicker, setHighlightDaPicker] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (user.cadastro_tipo_id === 3) {
          const { data: response } = await api.get(
            `/cadastro/${user.cadastro_id}`
          );

          if (response.ativo === 0) {
            toast.warning(
              "Sem permissão para fazer pedido/cotação. Favor contatar a mesa de operação"
            );
            return history.push("/NotAuthorized");
          }
        }

        setShowContent(false);
        const { data: date } = await api.get("/utilitarios/get-utc-time");
        const { data: responseHolidays } = await api.get(
          "/utilitarios/get-feriados"
        );
        const data = format(addDays(parseISO(date), 0), "yyyy-MM-dd");

        setMinDate(data);
        setMaxDate(addDays(parseISO(date), 90));
        setServerDate(parseISO(date));
        const formatHolidays = responseHolidays.map((item) => {
          return parseISO(item?.data);
        });

        setHighlightDaPicker([
          {
            "react-datepicker__day--highlighted-feriados": formatHolidays,
          },
        ]);

        setHolidays(formatHolidays);
        // Bloqueia  a programação do dia seguinte
        setExcludeDatesDatePicker((state) => [
          ...state,
          addDays(parseISO(date), 1),
        ]);

        const { data: response } = await api.get(`/numeracao/pedido`);
        setPedidoSistema(response.numeracao);
      } catch (error) {
        console.log(error);
      }

      if (parseInt(user.cadastro_tipo_id) === 3) {
        try {
          const { data: response } = await api.get(
            `/cadastro/${user.cadastro_id}`
          );

          const { data: datas } = await api.get(
            `programacao/${user.cadastro_id}/datas-programadas`
          );

          const formmatedDataProgramadas = datas.map((elem) =>
            parseISO(elem.data_programada)
          );

          setTodosPedidosProgramados(formmatedDataProgramadas);

          setExcludeDatesDatePicker((state) => [
            ...state.concat(formmatedDataProgramadas),
          ]);

          const cliente = response?.cpf_cnpj + " - " + response?.razao_social;
          const saldo = response.dados_cobranca.saldo_credito_total;
          const limite_credito = response.dados_cobranca.limite_credito;
          // const prazo = response.dados_cobranca.prazo_pagamento;
          const frete = response.dados_entrega.modalidade_transporte;
          // const abastecimento = response.dados_entrega.modo_abastecimento;

          const usuarios = user.user_id;

          setOperadorId(response.mesa_operacao.operador_id);
          setSaldo(saldo);
          setLimiteCredito(limite_credito);
          setTolerancia(response.dados_cobranca.tolerancia_maxima);
          // setPrazo(prazo);
          setArrayPrazo([
            {
              prazo: "",
            },
          ]);
          setCliente({
            value: response.id,
            label: cliente,
          });
          setFrete({ value: `${frete}`, label: `${frete}` });

          if ( response.dados_entrega.modo_abastecimento === "Bomba") {
            setAbastecimento({
              value: response.dados_entrega.modo_abastecimento,
              label: "Com Bomba",
            });
          } else {
            setAbastecimento({
              value:  response.dados_entrega.modo_abastecimento,
              label: "Sem Bomba",
            });
          }

          // setAbastecimento({
          //   value: `${abastecimento}`,
          //   label: `${abastecimento}`,
          // });


          setObservacoes(response.dados_entrega.observacao);
          setUsuarios(usuarios);
        } catch (error) {
          console.log(error);
        }

      try {
        const { data: response } = await api.get(`/menor/preco/${user.cadastro_id}`);

        let formattedList = response.map((produto) => {
          const obj = {};
          Object.assign(obj, {
            id: produto.produto_id,
            descricao: produto.descricao,
            valor_unitario: produto.preco?.replace(".", ","),
          });

          return obj;
        });

        setListaProdutos(formattedList);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [history, user.cadastro_id, user.cadastro_tipo_id, user.user_id]);

  const handleChangeInput = useCallback(
    (e) => {
      let newValue;

      // Comportamento da Mascara
      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (
        checkValueFormat[0].length <= 2 &&
        checkValueFormat[1]?.length < 2
      ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }

      setQuantidadeProduto(newValue || e.target.value);
    },
    []
  );

  const handleChangeInputExist = useCallback(
    (e, index) => {
      let newValue;

      // Comportamento da Mascara
      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (
        checkValueFormat[0].length <= 2 &&
        checkValueFormat[1]?.length < 2
      ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }

      listagemProdutos[index].quantidade = newValue || e.target.value;
      setListagemProdutos([...listagemProdutos]);
    },
    [listagemProdutos]
  );

  const handleLoadUsuarios = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.post(`/cadastro/filter`, {
        id,
      });

      const cliente = response.data[0].users;

      setUsuarios(null);

      if (cliente.length === 0) {
        setCheckUser(true);
        toast.warning("Não tem usuários cadastrados para esse cliente.");
      } else {
        setCheckUser(false);
      }

      const user = cliente.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.name,
        });

        return obj;
      });
      return user;
    } catch (error) {
      console.log(error, 'load user')
    }
  }, []);

  const handleLoadList = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data: response } = await api.get(`/menor/preco/${id}`);

      let formattedList = response.map((produto) => {
        console.log(produto)
        const obj = {};
        Object.assign(obj, {
          id: produto.produto_id,
          descricao: produto.descricao,
          valor_unitario: produto.preco?.replace(".", ","),
        });

        return obj;
      });

      setListaProdutos(formattedList);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleLoadTaxas = useCallback(async () => {
    try {
      if (user.cadastro_tipo_id === 3) {
        const { data: response } = await api.get(
          `/cadastro/${user.cadastro_id}`
        );

        let formattedTaxasFinan =
          response.taxa_financeira.map((taxa) => {
            return {
              value: taxa.taxa_financeira_de,
              label: taxa.taxa_financeira_de,
            };
          }) || [];

        // Limita Prazos com base no prazo de pagamento do cadastro
        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) => prazo?.value <= response?.dados_cobranca?.prazo_pagamento
        );

        // Remove do Array prazo que ja esta sendo usado.
        formattedTaxasFinan = formattedTaxasFinan.filter((prazo) => {
          return (
            arrayPrazo.findIndex((item) => item.prazo === prazo.value) === -1
          );
        });

        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) => prazo.value >= 4
        );

        if (formattedTaxasFinan.length === 0) {
          toast.warning("Cliente sem prazo mínimo de 4 dias.");
        }

        // Remove prazos abaixo de 4 - Reunião 15/06/21 Solicitação Erick

        return formattedTaxasFinan;
      } else {
        if (!cliente) {
          return;
        }

        const { data: response } = await api.get(`/cadastro/${cliente?.value}`);

        let formattedTaxasFinan =
          response.taxa_financeira.map((taxa) => {
            return {
              value: taxa.taxa_financeira_de,
              label: taxa.taxa_financeira_de,
            };
          }) || [];

        // Limita Prazos com base no prazo de pagamento do cadastro
        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) =>
            prazo?.value <= response?.dados_cobranca?.prazo_pagamento || 0
        );

        // Remove do Array prazo que ja esta sendo usado.
        formattedTaxasFinan = formattedTaxasFinan.filter((prazo) => {
          return (
            arrayPrazo.findIndex((item) => item.prazo === prazo.value) === -1
          );
        });

        // Remove prazos abaixo de 4 - Reunião 15/06/21 Solicitação Erick
        formattedTaxasFinan = formattedTaxasFinan.filter(
          (prazo) => prazo.value >= 4
        );

        if (formattedTaxasFinan.length === 0) {
          toast.warning("Cliente sem prazo mínimo de 4 dias.");
        }

        return formattedTaxasFinan;
      }
    } catch (error) {
      console.log(error);
    }
  }, [arrayPrazo, cliente, user.cadastro_id, user.cadastro_tipo_id]);

  const handleLoadInfo = useCallback(
    async (id) => {
      setFrete("");
      // setPrazo("");
      setAbastecimento("");
      setObservacoes("");
      setDataProgramacao([
        {
          date: undefined,
        },
      ]);
      setHasPrazo(true);

      if (!id) {
        return;
      }

      try {
        const { data: response } = await api.get(`/cadastro/${id}`);

        const { data: datas } = await api.get(
          `programacao/${id}/datas-programadas`
        );

        const formmatedDataProgramadas = datas.map((elem) =>
          parseISO(elem.data_programada)
        );

        setTodosPedidosProgramados(formmatedDataProgramadas);

        setHighlightDaPicker([
          {
            "react-datepicker__day--highlighted-feriados": holidays,
          },
          {
            "react-datepicker__day--highlighted-programados":
              formmatedDataProgramadas,
          },
        ]);

        // Bloqueia  a programação do dia seguinte e Feriados
        setExcludeDatesDatePicker((state) => {
          return [addDays(serverDate, 1)].concat(formmatedDataProgramadas);
        });

        const saldo = response.dados_cobranca?.saldo_credito_total || "";

        const limite_credito = response.dados_cobranca.limite_credito || "";
        const prazo = response.dados_cobranca?.prazo_pagamento || 0;
        const frete = response.dados_entrega?.modalidade_transporte || "";
        // const abastecimento = response.dados_entrega?.modo_abastecimento || "";
        const obs = response.dados_entrega?.observacao || "";

        setOperadorId(response.mesa_operacao.operador_id);
        setLimiteCredito(limite_credito);
        setTolerancia(response.dados_cobranca.tolerancia_maxima || "");

        setSaldo(saldo);

        if (prazo === "" || !prazo) {
          setHasPrazo(false);
        }

        setArrayPrazo([
          {
            prazo: "",
          },
        ]);

        // setPrazo(prazo);
        setFrete({ value: `${frete}`, label: `${frete}` });
        setObservacoes(obs);
        // setAbastecimento({
        //   value: `${abastecimento}`,
        //   label: `${abastecimento}`,
        // });

        if ( response.dados_entrega.modo_abastecimento === "Bomba") {
          setAbastecimento({
            value: response.dados_entrega.modo_abastecimento,
            label: "Com Bomba",
          });
        } else {
          setAbastecimento({
            value:  response.dados_entrega.modo_abastecimento,
            label: "Sem Bomba",
          });
        }

      } catch (error) {
        console.log(error);
      }
    },
    [holidays, serverDate]
  );

  const handleLoadPedido = useCallback(async () => {
    setPedidoSistema("");

    try {
      const { data: response } = await api.get(`/numeracao/pedido`);
      setPedidoSistema(response.numeracao);
    } catch (error) {
      console.log(error, 'handle pedido')
      setPedidoSistema("");
    }
  }, []);

  const handleAddTaxInput = useCallback(() => {
    setArrayPrazo([...arrayPrazo, { prazo: "" }]);
  }, [arrayPrazo]);

  const handleChangeInputPrazo = useCallback(
    (e, index) => {
      arrayPrazo[index].prazo = e.value;
      setArrayPrazo([...arrayPrazo]);
    },
    [arrayPrazo]
  );

  const handleRemoveInputPrazo = useCallback((position) => {
    setArrayPrazo((state) =>
      state.filter((_, index) => {
        return index !== position;
      })
    );
  }, []);

  const loadProdutos = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.post("/produto/filter", {
        descricao: inputValue
      });

      let produtosFormatted = response.data.map((prod) => {
        const objeto = {
          value: String(prod.id),
          label: prod.descricao,
        };

        return objeto;
      });

      produtosFormatted = produtosFormatted.filter((produto) => {
        return (
          listagemProdutos.findIndex((item) => parseInt(item.produto_id) === parseInt(produto.value)) === -1
        );
      });

      return produtosFormatted;
    } catch (error) {
      console.log(error, 'array produto')
    }
  }, [listagemProdutos]);


  const handleChangeProduto = useCallback(async (e) => {
    setAddProdutoButton(true)
    setMenorPrecoSelected("");
    listaProdutos.forEach((produto_id) => {
      if (parseInt(e) === parseInt(produto_id.id)){
        setMenorPrecoSelected(produto_id?.valor_unitario)
      }
    })
    setAddProdutoButton(false)
  }, [listaProdutos]);

  const handleAddProduto = useCallback(() => {
    if (produtoSelected.label === "" || produtoSelected.label === undefined ){
      toast.error("Selecione um produto");
      return
    }
    if (quantidadeProduto === ""){
      toast.error("Informe a quantidade");
      return
    }
    setListagemProdutos([...listagemProdutos,
      {
        produto_id: parseFloat(produtoSelected.value),
        descricao: produtoSelected.label,
        valor_unitario: menorPrecoSelected?.replaceAll(",", "."),
        quantidade: quantidadeProduto
      }
    ]);

    setProdutoSelected([]);
    setMenorPrecoSelected("");
    setQuantidadeProduto("");

  }, [listagemProdutos, menorPrecoSelected, produtoSelected, quantidadeProduto]);

  const handleRemoveProduto = useCallback((position) => {
    setListagemProdutos((state) =>
      state.filter((_, index) => {
        return index !== position;
      })
    );

  }, []);

  const afterDays = (date) => {
    //const day = getDay(date);
    return date >= new Date();
  };

  const handleAddProgramDate = useCallback(() => {
    if (dataProgramacao.length >= 6) {
      return;
    }
    setDataProgramacao([...dataProgramacao, { date: undefined }]);
  }, [dataProgramacao]);

  const handleRemoveProgramDate = useCallback(
    (position) => {
      var verifyDates = [];
      setDataProgramacao((state) => {
        verifyDates = state.filter((_, index) => {
          return index !== position;
        });

        verifyDates = verifyDates
          .filter((item) => item.date !== undefined)
          .map((i) => i.date);

        return state.filter((_, index) => {
          return index !== position;
        });
      });

      setExcludeDatesDatePicker((state) => {
        const newState = [addDays(serverDate, 1)].concat(verifyDates);

        return newState;
      });
    },
    [serverDate]
  );

  const handleChangeProgramDate = useCallback(
    (e, index) => {
      dataProgramacao[index].date = e;

      setDataProgramacao([
        ...dataProgramacao.sort((a, b) => compareAsc(a.date, b.date)),
      ]);

      var verifyDates = [];
      verifyDates = dataProgramacao
        .filter((item) => item.date !== undefined)
        .map((i) => i.date);

      setExcludeDatesDatePicker((state) => {
        const newState = [addDays(serverDate, 1)].concat(verifyDates);

        return newState;
      });
    },
    [dataProgramacao, serverDate]
  );

  const handleCreate = useCallback(async () => {

    var idPedidoTeste = ""
    setLoadingButton(true);

    var hoje = moment().format("YYYY-MM-DD 00:00:00");
    var dataPedido = moment(date).format("YYYY-MM-DD 00:00:00");
    var maxDataPedido = moment().add(1, 'years').format("YYYY-MM-DD 00:00:00")


    if (!(hoje <= dataPedido) || dataPedido > maxDataPedido ){
      toast.error("Por favor, preencha uma data válida.");
      setErrors(null);
      setLoadingButton(false);
     return
    }

    let listaProdutosFormmated = listagemProdutos.map((produto) => {
      return {
        produto_id: produto.produto_id,
        valor_unitario: produto.valor_unitario?.replaceAll(",", "."),
        quantidade:
          produto?.quantidade &&
          parseFloat(
            produto?.quantidade?.replaceAll(".", "").replaceAll(",", ".")
          ) * 1000,
      };
    });

    listaProdutosFormmated = listaProdutosFormmated.filter((item) => {
      if (item.quantidade === undefined) {
        return false;
      } else if (item.quantidade === 0) {
        return false;
      } else if (item.quantidade === null) {
        return false;
      } else if (item.quantidade === "") {
        return false;
      } else {
        return item;
      }
    });

    const prazo_1 = !arrayPrazo[0]?.prazo ? 0 : parseInt(arrayPrazo[0]?.prazo);
    const prazo_2 = !arrayPrazo[1]?.prazo ? 0 : parseInt(arrayPrazo[1]?.prazo);
    const prazo_3 = !arrayPrazo[2]?.prazo ? 0 : parseInt(arrayPrazo[2]?.prazo);
    const prazo_4 = !arrayPrazo[3]?.prazo ? 0 : parseInt(arrayPrazo[3]?.prazo);
    const prazo_5 = !arrayPrazo[4]?.prazo ? 0 : parseInt(arrayPrazo[4]?.prazo);

    const arr = [prazo_1, prazo_2, prazo_3, prazo_4, prazo_5].filter(
      (i) => i !== 0
    );

    var novaArr = arr.filter((prazo, i) => arr.indexOf(prazo) === i);

    const data = {
      status_pedido: cotacao,
      cadastro_id: cliente?.value,
      user_id: usuarios?.value || usuarios,
      numero_pedido_cliente: pedidoCliente,
      numero_pedido_sistema: pedidoSistema,
      data_entrega_desejada: date,
      frete: frete?.value,
      modo_abasteciemento: abastecimento?.value,
      periodo_desejado: periodo?.value,
      horario_desejado: horario + ":00",
      produtos: listaProdutosFormmated,
      prazo: prazo_1 !== 0 ? prazo_1 : "",
      prazos: novaArr,
      criado_por_user_id: user.user_id,
      observacao: observacoes || "",
    };

    if (frete?.value === "FOB (Retira)") {
      Object.assign(data, {
          motorista_nome: nomeMotorista,
          motorista_rg: RgMotorista?.replaceAll(".", "").replaceAll("-", ""), //?.replaceAll(".", "").replaceAll("-", ""),
          motorista_placa: placaVeiculo,
          motorista_tel: celular,
          motorista_cpf: cpfMotorista?.replaceAll(".", "").replaceAll("-", ""),
      });
    }

    // Adiciona obj se tem datas para programação

    let formattedDates = dataProgramacao.filter(
      (item) => item.date !== undefined
    );

    if (formattedDates.length > 0) {
      formattedDates = dataProgramacao.map((date) => {
        return {
          data_programada: format(date.date, "yyyy-MM-dd"),
        };
      });
      Object.assign(data, {
        programacao: formattedDates,
      });
    }

    if (user.cadastro_tipo_id === 3) {
      // Operador da mesa do cliente
      Object.assign(data, { operador_id: operadorId });
    } else if (user.cadastro_tipo_id === 1) {
      Object.assign(data, { operador_id: user.user_id });
    }

    try {
      // Cria o Pedido
      const { data: response } = await api.post("/pedido", data);

      idPedidoTeste = response.id

      toast.success(<FormattedMessage id="pedidoCriadoComSucesso" />);
      toast.success("Seu e-mail está sendo enviado, aguarde.");

      if (user.cadastro_tipo_id === 1) {
        setCliente("");
        setObservacoes("");
      }
      setPedidoCliente("");

      const { data: responseNumeroPedido } = await api.get(`/numeracao/pedido`);
      setPedidoSistema(responseNumeroPedido?.numeracao);

      setDate("");
      setPeriodo("");
      setHorario("");
      setDataProgramacao([{ date: undefined }]);

      setArrayPrazo([
        {
          prazo: "",
        },
      ]);

      if (parseInt(user.cadastro_tipo_id) === 1) {
        // setPrazo("");
        setFrete("");
        setSaldo("");
        setLimiteCredito("");
        setObservacoes("");
        setTolerancia("");
        setAbastecimento("");
        setUsuarios("");
        setListagemProdutos([]);
        setMotorista("");
        setCpfMotorista("");
        setRgMotorista("");
        setPlacaVeiculo("");
        setCelular("");
      }

      if (parseInt(user.cadastro_tipo_id) === 3) {
        setListagemProdutos([]);
      }

      setErrors(null);
      setLoadingButton(false);
    } catch (error) {
      console.log(error, 'envio pedido')

      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);

      if (error?.response?.status === 406) {
        toast.error("Cadastro inativo para criar pedido.");
      } else {
        toast.error(<FormattedMessage id="erroCriarRegistro" />);
      }
    }


    if (idPedidoTeste) {
      try {
        await api.get(`/email/pedido-criado/${idPedidoTeste}`);
        toast.success("Email de criação de pedido enviada com sucesso.");
        idPedidoTeste = "";
      } catch (error) {
        toast.error("Erro no envio do e-mail de criação de pedido.");
        idPedidoTeste = "";
      }
    }

  }, [
    arrayPrazo,
    cotacao,
    cliente.value,
    usuarios,
    pedidoCliente,
    pedidoSistema,
    date,
    frete.value,
    abastecimento.value,
    periodo.value,
    horario,
    user.user_id,
    user.cadastro_tipo_id,
    dataProgramacao,
    operadorId,
    // handleLoadPedido,
    observacoes,
    nomeMotorista,
    RgMotorista,
    placaVeiculo,
    celular,
    cpfMotorista,
    listagemProdutos
  ]);

  if (showContent) {
    return <LoadingContent />;
  }

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="novoPedido" />
          </h4>
        </header>

        <form onSubmit={(e) => e.preventDefault()}>
          <HeaderOrder>
            <div></div>

            <div className="infoPedido">
              <InfoOrder>
                <div>
                  <p>
                    <FormattedMessage id="limiteCreditoDisponivel" />
                  </p>
                  <strong>
                    {isNaN(limiteCredito)
                      ? (0).toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL",
                        })
                      : parseFloat(
                          limiteCredito * (tolerancia / 100 + 1) || 0
                        ).toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL",
                        })}
                  </strong>
                </div>
                <div>
                  <p>
                    <FormattedMessage id="seuLimiteSaldoDisponivel" />
                  </p>
                  <strong>
                    {isNaN(saldo)
                      ? (0).toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL",
                        })
                      : parseFloat(saldo || 0).toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "BRL",
                        })}
                  </strong>
                </div>

                <div style={{ width: "100px", marginBottom: "-5px" }}>
                  <h5>Status</h5>
                  <h5>{cotacao}</h5>
                </div>
              </InfoOrder>
            </div>
          </HeaderOrder>

          <div className="sideBySide">
            <div>
              <header className="header-two">
                <h4>
                  <FormattedMessage id="dadosPedido" />
                </h4>
              </header>
            </div>

            {parseInt(user.cadastro_tipo_id) === 1 ? (
              <>
                <AsyncListSelect
                  value={cliente}
                  labelPlaceholder={clientePlaceholder}
            required={true}
                  placeholder={<FormattedMessage id="selecionar" />}
                  onChange={(e) => {
                    setCliente(e);
                    setListaUsuarios(e?.value);
                    handleLoadList(e?.value);
                    // setIdCadastro(e?.value);
                    handleLoadInfo(e?.value);
                    setArrayPrazo([]);
                  }}
                  myOptions={"clientesv1"}
                  cacheOptions
                  defaultOptions
                  geterror={errors?.cadastro_id}
                />

                <AsyncListSelect
                  key={listaUsuarios}
                  labelPlaceholder={usuarioPlaceholder}
            required={true}
                  placeholder={
                    checkUser ? (
                      <FormattedMessage id="semUsuariosCadastrados" />
                    ) : (
                      <FormattedMessage id="selecionar" />
                    )
                  }
                  value={usuarios}
                  onChange={(e) => {
                    setUsuarios(e);
                  }}
                  cacheOptions
                  defaultOptions
                  loadOptions={() => {
                    return handleLoadUsuarios(listaUsuarios);
                  }}
                  noOptionsMessage={() => (
                    <FormattedMessage id="selecioneClienteCarregar" />
                  )}
                  loadingMessage={() => <FormattedMessage id="carregando" />}
                  isSearchable={false}
                  isDisabled={checkUser}
                  geterror={errors?.user_id}
                />
              </>
            ) : (
              <AsyncListSelect
                value={cliente}
                labelPlaceholder={clientePlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                onChange={(e) => {
                  setCliente(e);
                }}
                isDisabled
                myOptions={"clientes"}
                cacheOptions
                defaultOptions
                geterror={errors?.cadastro_id}
              />
            )}

            {arrayPrazo.length !== 0 ? (
              <>
                <div className="prazo">
                  <ButtonIcon
                    Icon={FiPlus}
                    style={{ height: "25px", width: "25px" }}
                    onClick={handleAddTaxInput}
                    geterror={errors?.taxa_financeira}
                    disabled={arrayPrazo.length <= 4 ? false : true}
                    backgroundColor={
                      arrayPrazo.length <= 4 ? "#ff7375" : "#c3c3c3"
                    }
                    placeholder={arrayPrazo.length <= 4 ? "Incluir" : ""}
                  />
                </div>

                <FormTax>
                  <strong>
                    <FormattedMessage id="prazoPagamentoEmDias" /> *
                  </strong>
                  <div className="grid grid-template-columns-3">
                    {arrayPrazo.map((prazo, index) => {
                      return (
                        <div key={index}>
                          <ul>
                            <div>
                              <li>
                                <AsyncListSelect
                                  key={cliente}
                                  ref={prazoRef}
                                  value={
                                    prazo.prazo
                                      ? {
                                          value: prazo.prazo,
                                          label: prazo.prazo,
                                        }
                                      : null
                                  }
                                  placeholder={
                                    <FormattedMessage id="selecionar" />
                                  }
                                  onChange={(e) => {
                                    return handleChangeInputPrazo(e, index);
                                  }}
                                  loadOptions={handleLoadTaxas}
                                  cacheOptions
                                  defaultOptions
                                  isSearchable={false}
                                  geterror={errors?.cadastro_id}
                                />

                                <ButtonIcon
                                  Icon={FiTrash2}
                                  color={"#fff"}
                                  style={{
                                    height: "27px",
                                    width: "27px",
                                    float: "right",
                                  }}
                                  onClick={() => handleRemoveInputPrazo(index)}
                                  disabled={
                                    arrayPrazo.length === 1 ? true : false
                                  }
                                  backgroundColor={"#B30000"}
                                  placeholder={
                                    arrayPrazo.length === 1 ? (
                                      ""
                                    ) : (
                                      <FormattedMessage id="excluir" />
                                    )
                                  }
                                />
                              </li>
                            </div>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                  {!hasPrazo && (
                    <LoadingPrazos>
                      Nenhum prazo, contate a mesa de operação!
                    </LoadingPrazos>
                  )}
                </FormTax>
              </>
            ) : (
              <LoadingPrazos>Carregando prazos...</LoadingPrazos>
            )}

            <InputLabel
              label={<FormattedMessage id="numeroPedidoSistema" />}
              type="text"
              value={pedidoSistema}
              readOnly
              geterror={errors?.numero_pedido_sistema}
            />

            <InputMaskDefault
              name="pedidoCliente"
              mask={"int15"}
              label={<FormattedMessage id="numeroPedidoCliente" />}
              onChange={(e) => setPedidoCliente(e.target.value)}
              type="text"
              value={pedidoCliente}
              geterror={errors?.numero_pedido_cliente}
            />
          </div>

          <div className="sideBySideTwo">
            <div>
              <header className="header-two">
                <h4>
                  <FormattedMessage id="dadosEntrega" />
                </h4>
              </header>
            </div>
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                width: "100%",
              }}
            >
              <InputLabel
                name="data_entrega_desejada"
                label={dataEntregaDesejadaPlaceholder}
            required={true}
                type="date"
                min={minDate}
                style={{ color: "hsl(0,0%,50%)", width: "100%" }}
                value={date}
                onChange={(e) => {
                  e.persist();
                  setDataProgramacao([{ date: undefined }]);
                  setExcludeDatesDatePicker(() => {
                    return [addDays(serverDate, 1)]
                      .concat(todosPedidosProgramados)
                      .concat([parseISO(e.target?.value)]);
                  });
                  setDate(e.target?.value);
                }}
                geterror={errors?.data_entrega_desejada}
              />
              {date && (
                <Button
                  type="button"
                  onClick={() => setShowScreen(!showScreen)}
                  style={{ marginLeft: 20, marginTop: 10 }}
                >
                  {<FormattedMessage id="programar" />}
                </Button>
              )}
            </div>

            <ListSelect
              name="frete"
              labelPlaceholder={fretePlaceholder}
            required={true}
              placeholder={<FormattedMessage id="selecionar" />}
              cacheOptions
              defaultOptions
              options={freteOptions}
              value={frete}
              onChange={(e) => setFrete(e)}
              geterror={errors?.frete}
            />

            {frete?.value === "FOB (Retira)" && (
              <Button
                type="button"
                onClick={() => {
                  setShowInfoMotorista(!showInfoMotorista);
                }}
              >
                {<FormattedMessage id="infoMotorista" />}
              </Button>
            )}

            {showInfoMotorista && (
              <InfoMotoristaContainer>
                <header>
                  <h4>{<FormattedMessage id="infoMotorista" />}</h4>
                  <FiXCircle
                    onClick={() => setShowInfoMotorista(!showInfoMotorista)}
                  />
                </header>
                <main>
                  <InputLabel
                    label={<FormattedMessage id="nomeMotorista" />}
                    value={nomeMotorista}
                    onChange={(e) => setMotorista(e.target.value)}
                    readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                    maxLength={50}
                  />

                  <InputLabel
                    label={<FormattedMessage id="rgMotorista" />}
                    value={RgMotorista}
                    onChange={(e) => setRgMotorista(e.target.value)}
                    readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                    mask={MaskInput.RG}
                  />
                  <InputLabel
                    label={<FormattedMessage id="cpfMotorista" />}
                    value={cpfMotorista}
                    onChange={(e) => setCpfMotorista(e.target.value)}
                    readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                    mask={MaskInput.CPF}
                  />
                  <InputLabel
                    label={<FormattedMessage id="placaVeiculo" />}
                    value={placaVeiculo}
                    onChange={(e) => setPlacaVeiculo(e.target.value)}
                    readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                    maxLength={8}
                  />
                  <InputLabel
                    label={<FormattedMessage id="celular" />}
                    value={celular}
                    onChange={(e) => setCelular(e.target.value)}
                    mask={MaskInput.cellphone}
                    readOnly={user.cadastro_tipo_id !== 2 ? false : true}
                  />
                </main>
                <br />
              </InfoMotoristaContainer>
            )}

            <ListSelect
              labelPlaceholder={modoAbastecimentoPlaceholder}
            required={true}
              placeholder={<FormattedMessage id="selecionar" />}
              value={abastecimento}
              cacheOptions
              defaultOptions
              onChange={(e) => setAbastecimento(e)}
              options={abastecimentoOptions}
              geterror={errors?.modo_abasteciemento}
            />

            <ListSelect
              labelPlaceholder={periodoDesejadoPlaceholder}
            required={true}
              placeholder={<FormattedMessage id="selecionar" />}
              value={periodo}
              cacheOptions
              defaultOptions
              onChange={(e) => setPeriodo(e)}
              options={periodoOptions}
              geterror={errors?.periodo_desejado}
            />

            <InputLabel
              label={horarioDesejadoPlaceholder}
            required={true}
              value={horario}
              mask={MaskInput.hour}
              placeholder={"00:00"}
              onChange={(e) => setHorario(e.target.value)}
              geterror={errors?.horario_desejado}
            />

            <TextArea
              maxLength={"200"}
              placeholder={"Observações - Máx. 200 caracteres"}
              value={observacoes}
              onChange={(e) => setObservacoes(e.target.value)}
            />
          </div>
        </form>

        <br />

        <FormProducts>
          <header></header>
          <ul style={{width: "88%"}}>
            <div>
              <li>
                <TitleTax>
                  <FormattedMessage id="produto" />
                </TitleTax>
              </li>
              <li>
                <TitleTax>
                  <FormattedMessage id="precoReferenciaLitro" />
                </TitleTax>
              </li>
              <li>
                <TitleTax>
                  <FormattedMessage id="quantidade" /> (M³)
                </TitleTax>
              </li>
            </div>
          </ul>

          {listaProdutos.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "50px",
              }}
            >
              <img  src={Alert} alt="" />
              <h3
                style={{
                  color: "#B30000",
                  marginLeft: "10px",
                  fontSize: "15px",
                  marginTop: '7px'
                }}
              >
                O cliente selecionado não possui base de distribuição relacionada.
              </h3>
            </div>
          ) : (
            <ul>
                <section>
                  <li>
                    <AsyncListSelect
                      key={produtoSelected}
                      value={produtoSelected}
                      placeholder={<FormattedMessage id="selecionar" />}
                      onChange={(e) => {
                        handleChangeProduto(e.value);
                        setProdutoSelected(e)
                      }}
                      loadOptions={loadProdutos}
                      ref={produtoRef}
                      cacheOptions
                      defaultOptions
                    />
                  </li>
                  <li>
                    <InputLabel
                      type="text"
                      name="valor_unitario"
                      value={menorPrecoSelected}
                      readOnly
                    />
                  </li>
                  <li>
                    <InputMaskDefault
                      mask={"volumePedido"}
                      type="text"
                      value={quantidadeProduto}
                      onChange={(e) => {
                        return handleChangeInput(e);
                      }}
                    />
                  </li>
                  <div className="produto">
                    <Button
                      onClick={(e) => {
                        handleAddProduto()
                      }}
                      backgroundColor={"#221c46"}
                      placeholder={"Incluir"}
                      loading={addProdutoButton}
                    >
                      Incluir Produto
                    </Button>
                  </div>

                </section>

              {listagemProdutos.map((data, index) => {
                return (
                  <section style={{justifyItems: "center"}} key={index}>
                    <li>
                      <p style={{ fontWeight: "600", marginTop: "10px" }}>
                        {data?.descricao}
                      </p>
                    </li>
                    <li style={{textAlign: 'center'}}>
                    <p style={{ marginTop: "10px" }}>{data?.valor_unitario?.replace(".", ",")}</p>
                    </li>
                    <li>
                      <InputMaskDefault
                        mask={"volumePedido"}
                        type="text"
                        value={data?.quantidade}
                        onChange={(e) => {
                          return handleChangeInputExist(e, index);
                        }}
                        geterrorcolorbackground={
                          errors?.[`produtos.${index}.quantidade`]
                        }
                      />
                    </li>
                    <div className="prazo" style={{marginTop: '9px'}}>
                      <ButtonIcon
                        Icon={FiTrash2}
                        style={{ height: "27px", width: "27px" }}
                        onClick={(e) => handleRemoveProduto(index)}
                        backgroundColor={"#B30000"}
                        placeholder={"Remover"}
                        loading={addProdutoButton}
                      />
                    </div>
                  </section>
                );
              })}
            </ul>
          )}

          <br />

          <MessageDefault
            geterror={errors?.saldo_credito_total}
            style={{ float: "right" }}
          />
        </FormProducts>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="enviar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
      {showScreen && (
        <ProgramOrderContainer>
          <header>
            <h3>Programar Pedido</h3>
            <FiXCircle onClick={() => setShowScreen(!showScreen)} />
          </header>
          <main>
            <header>
              <h5>Adicionar datas para programação</h5>
              <ButtonIcon
                Icon={FiPlus}
                backgroundColor={"#221c46"}
                onClick={() => handleAddProgramDate()}
              />
            </header>
            <div>
              {dataProgramacao.map((item, index) => {
                return (
                  <div key={index}>
                    <strong>{index + 1}º</strong>
                    <DatePickerComponent
                      selected={dataProgramacao[index]?.date}
                      onChange={(date) => handleChangeProgramDate(date, index)}
                      locale={"pt"}
                      dateFormat={"dd/MM/yyyy"}
                      filterDate={afterDays} // Remove fim de semana
                      excludeDates={excludeDatesDatePicker}
                      highlightDates={highlightDaPicker}
                      withPortal
                      maxDate={maxDate}
                    >
                      <FooterDatePicker>
                        <div>
                          <div
                            className={
                              "react-datepicker__day--highlighted-feriados"
                            }
                          ></div>
                          <strong>Feriados</strong>
                        </div>
                        <div>
                          <div
                            className={
                              "react-datepicker__day--highlighted-programados"
                            }
                          ></div>
                          <strong>Data com pedido programados</strong>
                        </div>
                      </FooterDatePicker>
                    </DatePickerComponent>
                    <ButtonIcon
                      Icon={FiTrash2}
                      color={"#fff"}
                      onClick={() => handleRemoveProgramDate(index)}
                      backgroundColor={"#B30000"}
                      placeholder={<FormattedMessage id="excluir" />}
                    />
                  </div>
                );
              })}
            </div>
          </main>
        </ProgramOrderContainer>
      )}
    </Container>
  );
};

export default injectIntl(CreateOrders);
