import React, { useEffect, useState, useContext } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR, enAU, es } from "date-fns/locale";
import moment from "moment";

import Language from "../../context/language";

import { FormattedMessage, injectIntl } from "react-intl";

const SodexoFaturamento = ({ dataFilter, intl }) => {
  const [loadingList, setLoadingList] = useState(true);

  const { language } = useContext(Language);

  const [labels, setLabels] = useState([]);
  const [graphBVI, setGraphBVI] = useState([]);
  const [graphBV, setGraphBV] = useState([]);

  //Placeholders internacionalizados
  const valorFaturadoComTaxasPlaceholder = intl.formatMessage({
    id: "valorFaturadoComTaxas",
  });
  const valorFaturadoSemTaxasPlaceholder = intl.formatMessage({
    id: "valorFaturadoSemTaxas",
  });

  let loadGraph = {
    series: [
      {
        name: valorFaturadoComTaxasPlaceholder,
        description: "Teste",
        data: graphBVI,
      },
      {
        name: valorFaturadoSemTaxasPlaceholder,
        data: graphBV,
      },
    ],

    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF7375", "#221C46"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      },
      labels: labels,
      tooltip: {
        y: {
          formatter: function (val) {
            return "R$ " + (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          }
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const dataBVI = [];
        const dataBV = [];
        const labels = [];

        const { data: response } = await api.get(`/dashboard/faturamento`, {
          params: {
            data_de: !dataFilter?.dataDeFilter
              ? dataDe
              : dataFilter?.dataDeFilter + " 00:00:00",
            data_ate: !dataFilter?.dataAteFilter
              ? dataAte
              : dataFilter?.dataAteFilter + " 23:59:59",
            cpf_cnpj: dataFilter?.cnpjFilter
              ?.replaceAll(".", "")
              ?.replaceAll("/", "")
              ?.replaceAll("-", "")
              ?.replaceAll("_", ""),
            grupo_economico: dataFilter?.grupoEconomicoFilter,
            base_distribuicao_id: dataFilter?.bases?.value,
            municipio_id: dataFilter?.cidadeBasesDist?.value,
            estado_id: dataFilter?.estadoBasesDist?.value,
          },
        });
        let setLanguage = ptBR;
        if (language === "en") {
          setLanguage = enAU;
        } else if (language === "es") {
          setLanguage = es;
        } else if (language === "pt_BR") {
          setLanguage = ptBR;
        }

        response.map((item) => {

          const obj = {};
          const date = item.data_group.split("-");

          Object.assign(obj, {
            dataBVI: dataBVI.push(item.bvi),
            dataBV: dataBV.push(item.bv),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: setLanguage,
              })
            ),
          });

          return obj;
        });

        setGraphBVI(dataBVI);
        setGraphBV(dataBV);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, language]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoGeralFaturamento" />
            </PanelHeader>
            <PanelBody className="p-0">

              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="bar"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default injectIntl(SodexoFaturamento);
