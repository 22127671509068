import React, { useContext, createContext, useState } from "react";
import AuthContext from "./../context/auth";

//Perfil de Acessos

import NotAuthorized from "./../pages/NotAuthorized";

/** SODEXO */
import ListAccessProfiles from "./../pages/Access/ListAccessProfiles";
import CreateAccessProfile from "./../pages/Access/CreateAccessProfiles";
import DisplayAccessProfile from "./../pages/Access/DisplayAccessProfile";
import EditAccessProfile from "./../pages/Access/EditAccessProfiles";
/** CUSTOMERS */
import ListAccessProfilesCustomers from "./../pages/AccessCustomers/ListAccessProfiles";
import CreateAccessProfileCustomer from "./../pages/AccessCustomers/CreateAccessProfiles";
import DisplayAccessProfileCustomer from "./../pages/AccessCustomers/DisplayAccessProfile";
import EditAccessProfileCustomer from "./../pages/AccessCustomers/EditAccessProfiles";
/** DISTRIBUTORS */
import ListAccessProfilesDistributors from "./../pages/AccessDistributors/ListAccessProfiles";
import CreateAccessProfileDistributor from "./../pages/AccessDistributors/CreateAccessProfiles";
import DisplayAccessProfileDistributor from "./../pages/AccessDistributors/DisplayAccessProfile";
import EditAccessProfileDistributor from "./../pages/AccessDistributors/EditAccessProfiles";

// Users
/** SODEXO */
import ListUser from "./../pages/Users/ListUsers";
import CreateUsers from "./../pages/Users/CreateUser";
import EditUser from "./../pages/Users/EditUser";
import DisplayUser from "./../pages/Users/DisplayUser";
import ChangeUserPassword from "./../pages/Users/ChangePassword";
/** CUSTOMERS */
import ListUserCustomers from "./../pages/UsersCustomers/ListUsers";
import CreateUsersCustomer from "./../pages/UsersCustomers/CreateUser";
import EditUserCustomer from "./../pages/UsersCustomers/EditUser";
import DisplayUserCustomer from "./../pages/UsersCustomers/DisplayUser";
import ChangeUserPasswordCustomer from "./../pages/UsersCustomers/ChangePassword";
/** DISTRIBUTORS */
import ListUserDistributors from "./../pages/UsersDistributors/ListUsers";
import CreateUsersDistributor from "./../pages/UsersDistributors/CreateUser";
import EditUserDistributor from "./../pages/UsersDistributors/EditUser";
import DisplayUserDistributor from "./../pages/UsersDistributors/DisplayUser";
import ChangeUserPasswordDistributor from "./../pages/UsersDistributors/ChangePassword";

//Combustíveis
import ListProductsFuels from "./../pages/ProductsFuels/ListProducts";
import CreateProductFuels from "./../pages/ProductsFuels/CreateProduct";
import DisplayProductFuels from "./../pages/ProductsFuels/DisplayProduct";
import EditProductFuels from "./../pages/ProductsFuels/EditProduct";

//Produtos
import ListProducts from "./../pages/Products/ListProducts";
import CreateProduct from "./../pages/Products/CreateProduct";
import DisplayProduct from "./../pages/Products/DisplayProduct";
import EditProduct from "./../pages/Products/EditProduct";

//Associação
import EditAssociation from "./../pages/Association/EditAssociation";

//Distribution Bases
import ListDistributionBases from "./../pages/DistributionBase/ListDistributionBases";
import CreateDistributionBase from "./../pages/DistributionBase/CreateDistributionBase";
import DisplayDistributionBase from "./../pages/DistributionBase/DisplayDistributionBase";
import EditDistributionBase from "./../pages/DistributionBase/EditDistributionBase";

////// Tables
// Customers Classification
import ListCustomerClassification from "./../pages/Tables/CustomerClassification/ListCustomerClassification";
import CreateCustomerClassification from "./../pages/Tables/CustomerClassification/CreateCustomerClassification";
import EditCustomerClassification from "./../pages/Tables/CustomerClassification/EditCustomerClassification";
import DisplayCustomerClassification from "./../pages/Tables/CustomerClassification/DisplayCustomerClassification";
// Distributors Classification
import ListDistributorClassification from "./../pages/Tables/DistributorClassification/ListDistributorClassification";
import CreateDistributorClassification from "./../pages/Tables/DistributorClassification/CreateDistributorClassification";
import EditDistributorClassification from "./../pages/Tables/DistributorClassification/EditDistributorClassification";
import DisplayDistributorClassification from "./../pages/Tables/DistributorClassification/DisplayDistributorClassification";
// Consultants
import ListConsultantsClassification from "./../pages/Tables/ConsultantsClassification/ListConsultantClassification";
import CreateConsultantsClassification from "./../pages/Tables/ConsultantsClassification/CreateConsultantClassification";
import EditConsultantsClassification from "./../pages/Tables/ConsultantsClassification/EditConsultantClassification";
import DisplayConsultantsClassification from "./../pages/Tables/ConsultantsClassification/DisplayConsultantClassification";
// Operation State
import ListOperationStation from "./../pages/Tables/OperationStation/ListOperationStation";
import CreateOperationStation from "./../pages/Tables/OperationStation/CreateOperationStation";
import EditOperationStation from "./../pages/Tables/OperationStation/EditOperationStation";
import DisplayOperationStation from "./../pages/Tables/OperationStation/DisplayOperationStation";
// Marcas
import ListBrands from "./../pages/Tables/Brands/ListBrands";
import CreateBrands from "./../pages/Tables/Brands/CreateBrands";
import EditBrands from "./../pages/Tables/Brands/EditBrands";
import DisplayBrands from "./../pages/Tables/Brands/DisplayBrands";
// Categoria
import ListCategory from "./../pages/Tables/Category/ListCategory";
import CreateCategory from "./../pages/Tables/Category/CreateCategory";
import EditCategory from "./../pages/Tables/Category/EditCategory";
import DisplayCategory from "./../pages/Tables/Category/DisplayCategory";

// Distributors TRR
import ListDistributorsTRRs from "./../pages/DistributorsTRRs/ListDistributors";
import CreateDistributorsTRRs from "./../pages/DistributorsTRRs/CreateDistributors";
import DisplayDistributorsTRRs from "./../pages/DistributorsTRRs/DisplayDistributors";
import EditDistributorsTRRs from "./../pages/DistributorsTRRs/EditDistributors";

//Clientes
import ListCustomers from "./../pages/Customers/ListCustomers";
import CreateCustomers from "./../pages/Customers/CreateCustomers";
import DisplayCustomers from "./../pages/Customers/DisplayCustomers";
import EditCustomers from "./../pages/Customers/EditCustomers";
import VersionCustomers from "./../pages/Customers/VersionCustomers";

// Orders - Preço de Combustível
import CreateOrders from "./../pages/Orders/Price/CreateOrders";
import ListOrders from "./../pages/Orders/Price/ListOrders";

// Orders - Valor Combustível
import CreatePriceFuel from "./../pages/Orders/PriceFuel/CreateOrders";
import ListPriceFuel from "./../pages/Orders/PriceFuel/ListOrders";

// Orders - Frete por Região
import CreateFreight from "./../pages/Orders/PriceFreight/CreateOrders";
import ListFreight from "./../pages/Orders/PriceFreight/ListOrders";

// Orders - Valor Produto
import CreatePriceProduct from "./../pages/Orders/PriceProduct/CreateOrders";
import ListPriceProduct from "./../pages/Orders/PriceProduct/ListOrders";

// Orders - Valor Frete Produto
import CreateProductFreight from "./../pages/Orders/PriceProductFreigh/CreateOrders";
import ListProductFreight from "./../pages/Orders/PriceProductFreigh/ListOrders";

// Orders - Paínel de Controle
import ListPainel from "./../pages/Orders/Painel/ListPainel/Sodexo";
import ListPainelv2 from "./../pages/Orders/Painel/ListPainel/Sodexov2";
import Simulation from "./../pages/Orders/Painel/ListPainel/Simulation";
import ListPainelCustomers from "./../pages/Orders/Painel/ListPainel/Customers";
import ListPainelDistributors from "./../pages/Orders/Painel/ListPainel/Distribuitors";

import OrdersPainelHistory from "./../pages/Orders/Painel/OrdersPainelHistory";
import ReprovePainelOrders from "./../pages/Orders/Painel/ReproveOrders";

import CreatePainelOrders from "./../pages/Orders/Painel/CreatePainelOrders";
import CreatePainelOrdersV2 from "./../pages/Orders/Painel/CreatePainelOrdersV2";
import EditPainelOrders from "./../pages/Orders/Painel/EditPainelOrders";
import DisplayPainelOrders from "./../pages/Orders/Painel/DisplayPainelOrders";

import CreatePainelOrdersProgram from "./../pages/Orders/Painel/CreatePainelOrdersProgram";

// Relatórios
import ReportOrders from "./../pages/Reports/Orders";
import ReportPriceCombustivel from "./../pages/Reports/PriceCombustivel";
import ReportPriceCombustivelFaturado from "./../pages/Reports/PriceCombustivelFaturado";
import ReportCustomerConditions from "./../pages/Reports/CustomerConditions";
import ReportLog from "./../pages/Reports/Log";
import ReportCustomerRegister from "./../pages/Reports/CustomerRegister";
import ReportDistributorsRegister from "./../pages/Reports/DistributorsRegister";
import ReportSaving from "./../pages/Reports/Saving";
import RefundDistributors from "./../pages/Reports/RefundDistributorsTRRs";
import ReportTradingHistory from "./../pages/Reports/TradingHistory";

// Comunicação
import CreateCommunication from "./../pages/Communication/CreateCommunication";
import DisplayCommunication from "./../pages/Communication/DisplayCommunication";
import EditCommunication from "./../pages/Communication/EditCommunication";
import ListCommunication from "./../pages/Communication/ListCommunication";

//Dashboard
import DashboardSodexo from "./../pages/Dashboard/DashboardSodexo";
import DashboardDistributors from "./../pages/Dashboard/DashboardDistributors";
import DashboardCustomers from "./../pages/Dashboard/DashboardCustomers";

//Home
import HomeSodexo from "./../pages/Home/HomeSodexo";
import HomeCustomer from "./../pages/Home/HomeCustomer";
import HomeDistributor from "./../pages/Home/HomeDistributors";

/** Change Password */
import ChangePassword from "./../pages/Users/ChangeLoginPassword";

const RoutesContext = createContext();

export const RoutesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const [routes] = useState([
    {
      //Rota de inicial ao entrar no sistema sodexo
      path: "/home",
      exact: true,
      component: () => <HomeSodexo />,
      cadastro_tipo_id: [1],
    },

    {
      //Rota de inicial ao entrar no sistema distribuidor
      path: "/home",
      exact: true,
      component: () => <HomeDistributor />,
      cadastro_tipo_id: [2],
    },

    {
      //Rota de inicial ao entrar no sistema cliente
      path: "/home",
      exact: true,
      component: () => <HomeCustomer />,
      cadastro_tipo_id: [3],
    },

    {
      //Rota de listagem dos perfis de acessos
      path: "/NotAuthorized",
      exact: true,
      title: "Não Autorizado",
      component: () => <NotAuthorized />,
      cadastro_tipo_id: [],
    },

    /** PERFIS (ROLES) */
    /** ROLES SODEXO */
    {
      //Rota de listagem dos perfis de acessos
      path: "/access/profiles/sodexo/list",
      exact: true,
      title: "Lista de perfis de acesso - Pluxee",
      component: () => <ListAccessProfiles />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-list[1]"
      ),
    },
    {
      //Rota de criação de um perfil de acesso
      path: "/access/profiles/sodexo/create",
      exact: true,
      title: "Criação de perfil - Sodexo",
      component: () => <CreateAccessProfile />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-store[1]"
      ),
    },
    {
      //Rota de exibição de um perfil de acesso
      path: "/access/profiles/sodexo/show",
      exact: true,
      title: "Editar de perfil acesso - Pluxee",
      component: () => <DisplayAccessProfile />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-show[1]"
      ),
    },
    {
      //Rota de edição de um perfil de acesso
      path: "/access/profiles/sodexo/edit",
      exact: true,
      title: "Editar de perfil acesso - Pluxee",
      component: () => <EditAccessProfile />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-update[1]"
      ),
    },
    /** ROLES CUSTOMERS */
    {
      //Rota de listagem dos perfis de acessos
      path: "/access/profiles/customers/list",
      exact: true,
      title: "Lista de perfis de acesso - Cliente",
      component: () => <ListAccessProfilesCustomers />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-list[3]"
      ),
    },
    {
      //Rota de criação de um perfil de acesso
      path: "/access/profiles/customers/create",
      exact: true,
      title: "Criação de perfil - Cliente",
      component: () => <CreateAccessProfileCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-store[3]"
      ),
    },
    {
      //Rota de exibição de um perfil de acesso
      path: "/access/profiles/customers/show",
      exact: true,
      title: "Editar de perfil acesso - Cliente",
      component: () => <DisplayAccessProfileCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-show[3]"
      ),
    },
    {
      //Rota de edição de um perfil de acesso
      path: "/access/profiles/customers/edit",
      exact: true,
      title: "Editar de perfil acesso - Cliente",
      component: () => <EditAccessProfileCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-update[3]"
      ),
    },
    /** ROLES DISTRIBUTORS */
    {
      //Rota de listagem dos perfis de acessos
      path: "/access/profiles/distributors/list",
      exact: true,
      title: "Lista de perfis de acesso - Distribuidores/TRR",
      component: () => <ListAccessProfilesDistributors />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-list[2]"
      ),
    },
    {
      //Rota de criação de um perfil de acesso
      path: "/access/profiles/distributors/create",
      exact: true,
      title: "Criação de perfil - Distribuidor/TRR",
      component: () => <CreateAccessProfileDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-store[2]"
      ),
    },
    {
      //Rota de exibição de um perfil de acesso
      path: "/access/profiles/distributors/show",
      exact: true,
      title: "Editar de perfil acesso - Distribuidor/TRR",
      component: () => <DisplayAccessProfileDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-show[2]"
      ),
    },
    {
      //Rota de edição de um perfil de acesso
      path: "/access/profiles/distributors/edit",
      exact: true,
      title: "Editar de perfil acesso - Distribuidor/TRR",
      component: () => <EditAccessProfileDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "roles-update[2]"
      ),
    },

    /** USERS SODEXO */
    {
      //Rota de listagem de usuários SODEXO
      path: "/access/users/sodexo/list",
      exact: true,
      title: "Lista de usuários - Sodexo",
      component: () => <ListUser />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-list[1]"
      ),
    },

    {
      //Rota de criação de usuário
      path: "/access/users/sodexo/create",
      exact: true,
      title: "Criação de usuário - Sodexo",
      component: () => <CreateUsers />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-store[1]"
      ),
    },

    {
      //Rota de edição de usuário
      path: "/access/users/sodexo/edit",
      exact: true,
      title: "Criação de usuário - Sodexo",
      component: () => <EditUser />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-update[1]"
      ),
    },

    {
      //Rota de exibição de usuário
      path: "/access/users/sodexo/show",
      exact: true,
      title: "Exibição de usuário - Sodexo",
      component: () => <DisplayUser />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-show[1]"
      ),
    },

    {
      //Rota de alteração de senha do usuário
      path: "/access/users/sodexo/change-password",
      exact: true,
      title: "Redefinição de senha - Pluxee",
      component: () => <ChangeUserPassword />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-redefinirsenha[1]"
      ),
    },

    /** USERS CUSTOMERS */
    {
      //Rota de listagem de usuários SODEXO
      path: "/access/users/customer/list",
      exact: true,
      title: "Lista de usuários - Pluxee",
      component: () => <ListUserCustomers />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-list[3]"
      ),
    },

    {
      //Rota de criação de usuário
      path: "/access/users/customer/create",
      exact: true,
      title: "Criação de usuário - Pluxee",
      component: () => <CreateUsersCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-store[3]"
      ),
    },

    {
      //Rota de edição de usuário
      path: "/access/users/customer/edit",
      exact: true,
      title: "Criação de usuário - Pluxee",
      component: () => <EditUserCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-update[3]"
      ),
    },

    {
      //Rota de exibição de usuário
      path: "/access/users/customer/show",
      exact: true,
      title: "Exibição de usuário - Pluxee",
      component: () => <DisplayUserCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-show[3]"
      ),
    },

    {
      //Rota de alteração de senha do usuário
      path: "/access/users/customer/change-password",
      exact: true,
      title: "Redefinição de senha - Pluxee",
      component: () => <ChangeUserPasswordCustomer />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-redefinirsenha[3]"
      ),
    },

    /** USERS DISTRIBUTORS */
    {
      //Rota de listagem de usuários DISTRIBUTOR
      path: "/access/users/distributor/list",
      exact: true,
      title: "Lista de usuários - Pluxee",
      component: () => <ListUserDistributors />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-list[2]"
      ),
    },

    {
      //Rota de criação de usuário DISTRIBUTOR
      path: "/access/users/distributor/create",
      exact: true,
      title: "Criação de usuário - Pluxee",
      component: () => <CreateUsersDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-store[2]"
      ),
    },

    {
      //Rota de edição de usuário DISTRIBUTOR
      path: "/access/users/distributor/edit",
      exact: true,
      title: "Criação de usuário - Pluxee",
      component: () => <EditUserDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-update[2]"
      ),
    },

    {
      //Rota de exibição de usuário DISTRIBUTOR
      path: "/access/users/distributor/show",
      exact: true,
      title: "Exibição de usuário - Pluxee",
      component: () => <DisplayUserDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-show[2]"
      ),
    },

    {
      //Rota de alteração de senha do usuário DISTRIBUTOR
      path: "/access/users/distributor/change-password",
      exact: true,
      title: "Redefinição de senha - Pluxee",
      component: () => <ChangeUserPasswordDistributor />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "usuario-redefinirsenha[2]"
      ),
    },
    /** ---------------------------------------------- */
    {
      //Rota de listagem de produtos
      path: "/register/sodexo/products-fuels/list",
      exact: true,
      title: "Listagem Produtos",
      component: () => <ListProductsFuels />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-list[1]"
      ),
    },

    {
      //Rota de criação de produto
      path: "/register/sodexo/products-fuels/create",
      exact: true,
      title: "Criar Produto",
      component: () => <CreateProductFuels />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-store[1]"
      ),
    },

    {
      //Rota de exibição do produto
      path: "/register/sodexo/products-fuels/show",
      exact: true,
      title: "Exibir Produto",
      component: () => <DisplayProductFuels />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-show[1]"
      ),
    },

    {
      //Rota de exibição do produto
      path: "/register/sodexo/products-fuels/edit",
      exact: true,
      title: "Editar Produto",
      component: () => <EditProductFuels />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-update[1]"
      ),
    },

     /** ---------------------------------------------- */
     {
      //Rota de listagem de produtos
      path: "/register/sodexo/products/list",
      exact: true,
      title: "Listagem Produtos",
      component: () => <ListProducts />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-list[1]"
      ),
    },

    {
      //Rota de criação de produto
      path: "/register/sodexo/products/create",
      exact: true,
      title: "Criar Produto",
      component: () => <CreateProduct />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-store[1]"
      ),
    },

    {
      //Rota de exibição do produto
      path: "/register/sodexo/products/show",
      exact: true,
      title: "Exibir Produto",
      component: () => <DisplayProduct />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-show[1]"
      ),
    },

    {
      //Rota de exibição do produto
      path: "/register/sodexo/products/edit",
      exact: true,
      title: "Editar Produto",
      component: () => <EditProduct />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-update[1]"
      ),
    },

     /** ---------------------------------------------- */
    {
      //Rota de exibição do produto
      path: "/register/sodexo/association/edit",
      exact: true,
      title: "Associação de Produtos em Geral",
      component: () => <EditAssociation />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "produto-update[1]"
      ),
    },


    {
      //Rota de listagem das bases de distribuições
      path: "/register/sodexo/distribution-base/list",
      exact: true,
      title: "Listar base de distribuição",
      component: () => <ListDistributionBases />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelabasedistribuicao-list[1]"
      ),
    },

    {
      //Rota de criação de bases de distribuições
      path: "/register/sodexo/distribution-base/create",
      exact: true,
      title: "Cadastrar Base de distribuição",
      component: () => <CreateDistributionBase />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelabasedistribuicao-store[1]"
      ),
    },

    {
      //Rota de exibição de uma base de distribuição
      path: "/register/sodexo/distribution-base/show",
      exact: true,
      title: "Exibir Base de distribuição",
      component: () => <DisplayDistributionBase />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelabasedistribuicao-show[1]"
      ),
    },

    {
      //Rota de edição de uma base de distribuição
      path: "/register/sodexo/distribution-base/edit",
      exact: true,
      title: "Exibir Base de distribuição",
      component: () => <EditDistributionBase />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelabasedistribuicao-update[1]"
      ),
    },

    {
      //Rota de listagem de Table - Classificação Cliente
      path: "/register/sodexo/tables/customer-classification/list",
      exact: true,
      title: "Classificação Clientes",
      component: () => <ListCustomerClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaocliente-list[1]"
      ),
    },

    {
      //Rota de criação de Table - Classificação Cliente
      path: "/register/sodexo/tables/customer-classification/create",
      exact: true,
      title: "Criação - Classificação Clientes",
      component: () => <CreateCustomerClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaocliente-store[1]"
      ),
    },

    {
      //Rota de edição de Table - Classificação Cliente
      path: "/register/sodexo/tables/customer-classification/edit",
      exact: true,
      title: "Edição - Classificação Clientes",
      component: () => <EditCustomerClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaocliente-update[1]"
      ),
    },

    {
      //Rota de Exibição de Table - Classificação Cliente
      path: "/register/sodexo/tables/customer-classification/show",
      exact: true,
      title: "Exibição - Classificação Clientes",
      component: () => <DisplayCustomerClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaocliente-show[1]"
      ),
    },

    {
      //Rota de listagem de Table - Classificação Consultores
      path: "/register/sodexo/tables/distribution-classification/list",
      exact: true,
      title: "Listagem - Classificação de Consultores",
      component: () => <ListDistributorClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaodistribuidor-list[1]"
      ),
    },

    {
      //Rota de criação de Table - Classificação Consultores
      path: "/register/sodexo/tables/distribution-classification/create",
      exact: true,
      title: "Criação - Classificação Consultores",
      component: () => <CreateDistributorClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaodistribuidor-store[1]"
      ),
    },

    {
      //Rota de edição de Table -Classificação Consultores
      path: "/register/sodexo/tables/distribution-classification/edit",
      exact: true,
      title: "Edição - Classificação Consultores",
      component: () => <EditDistributorClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaodistribuidor-update[1]"
      ),
    },

    {
      //Rota de Exibição de Table - Classificação Consultores
      path: "/register/sodexo/tables/distribution-classification/show",
      exact: true,
      title: "Exibição - Classificação Consultores",
      component: () => <DisplayDistributorClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaclassificacaodistribuidor-show[1]"
      ),
    },

    {
      //Rota de listagem de Table - Classificação Consultores
      path: "/register/sodexo/tables/consultants-classification/list",
      exact: true,
      title: "Listagem - Classificação Consultores",
      component: () => <ListConsultantsClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaconsultor-list[1]"
      ),
    },

    {
      //Rota de criação de Table - Classificação Consultores
      path: "/register/sodexo/tables/consultants-classification/create",
      exact: true,
      title: "Criação - Classificação Consultores",
      component: () => <CreateConsultantsClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaconsultor-store[1]"
      ),
    },

    {
      //Rota de edição de Table - Classificação Consultores
      path: "/register/sodexo/tables/consultants-classification/edit",
      exact: true,
      title: "Edição - Classificação Consultores",
      component: () => <EditConsultantsClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaconsultor-update[1]"
      ),
    },

    {
      //Rota de Exibição de Table - Classificação Consultores
      path: "/register/sodexo/tables/consultants-classification/show",
      exact: true,
      title: "Exibição - Classificação Consultores",
      component: () => <DisplayConsultantsClassification />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelaconsultor-show[1]"
      ),
    },

    ////////////////////////////////////
    {
      //Rota de listagem de Table - Marcas
      path: "/register/sodexo/tables/brands/list",
      exact: true,
      title: "Listagem - Marcas",
      component: () => <ListBrands />,
      cadastro_tipo_id: [1],
      permission: true
    },

    {
      //Rota de criação de Table - Marcas
      path: "/register/sodexo/tables/brands/create",
      exact: true,
      title: "Criação - Marcas",
      component: () => <CreateBrands />,
      cadastro_tipo_id: [1],
      permission: true
    },

    {
      //Rota de edição de Table - Marcas
      path: "/register/sodexo/tables/brands/edit",
      exact: true,
      title: "Edição - Marcas",
      component: () => <EditBrands />,
      cadastro_tipo_id: [1],
      permission: true
    },

    {
      //Rota de Exibição de Table - Marcas
      path: "/register/sodexo/tables/brands/show",
      exact: true,
      title: "Exibição - Marcas",
      component: () => <DisplayBrands />,
      cadastro_tipo_id: [1],
      permission: true
    },
    ////////////////////////////////////

    {
      //Rota de listagem de Table - Categorias
      path: "/register/sodexo/tables/category/list",
      exact: true,
      title: "Listagem - Categorias",
      component: () => <ListCategory />,
      cadastro_tipo_id: [1],
      permission: true
    },

    {
      //Rota de criação de Table - Categorias
      path: "/register/sodexo/tables/category/create",
      exact: true,
      title: "Criação - Categorias",
      component: () => <CreateCategory />,
      cadastro_tipo_id: [1],
      permission: true
    },

    {
      //Rota de edição de Table - Categorias
      path: "/register/sodexo/tables/category/edit",
      exact: true,
      title: "Edição - Categorias",
      component: () => <EditCategory />,
      cadastro_tipo_id: [1],
      permission: true
    },

    {
      //Rota de Exibição de Table - Categorias
      path: "/register/sodexo/tables/category/show",
      exact: true,
      title: "Exibição - Categorias",
      component: () => <DisplayCategory />,
      cadastro_tipo_id: [1],
      permission: true
    },
    ////////////////////////////////////

    {
      //Rota de listagem de Table - Mesa de Operações
      path: "/register/sodexo/tables/operation-station/list",
      exact: true,
      title: "Listagem - Mesa de Operações",
      component: () => <ListOperationStation />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelamesaoperacao-list[1]"
      ),
    },

    {
      //Rota de criação de Table - Mesa de Operações
      path: "/register/sodexo/tables/operation-station/create",
      exact: true,
      title: "Criação - Mesa de Operações",
      component: () => <CreateOperationStation />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelamesaoperacao-store[1]"
      ),
    },

    {
      //Rota de edição de Table - Mesa de Operações
      path: "/register/sodexo/tables/operation-station/edit",
      exact: true,
      title: "Edição - Mesa de Operações",
      component: () => <EditOperationStation />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelamesaoperacao-update[1]"
      ),
    },

    {
      //Rota de Exibição de Table - Mesa de Operações
      path: "/register/sodexo/tables/operation-station/show",
      exact: true,
      title: "Exibição - Mesa de Operações",
      component: () => <DisplayOperationStation />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "tabelamesaoperacao-show[1]"
      ),
    },

    {
      //Rota de listagem de Distribuidores TRR
      path: "/register/sodexo/distributors-trrs/list",
      exact: true,
      title: "Listagem - Distribuidores/TRRs",
      component: () => <ListDistributorsTRRs />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-distribuidor-list[1]"
      ),
    },

    {
      //Rota de criação de Distribuidores TRR
      path: "/register/sodexo/distributors-trrs/create",
      exact: true,
      title: "Criação - Distribuidores/TRRs",
      component: () => <CreateDistributorsTRRs />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-distribuidor-store[1]"
      ),
    },

    {
      //Rota de exibicao de Distribuidores TRR
      path: "/register/sodexo/distributors-trrs/show",
      exact: true,
      title: "Exibição - Distribuidor/TRRs",
      component: () => <DisplayDistributorsTRRs />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-distribuidor-show[1]"
      ),
    },

    {
      //Rota de edicao de Distribuidores TRR
      path: "/register/sodexo/distributors-trrs/edit",
      exact: true,
      title: "Edição - Distribuidor/TRRs",
      component: () => <EditDistributorsTRRs />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-distribuidor-update[1]"
      ),
    },

    {
      //Rota de listagem de Clientes
      path: "/register/sodexo/customers/list",
      exact: true,
      title: "Listagem - Clientes",
      component: () => <ListCustomers />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-cliente-list[1]"
      ),
    },

    {
      //Rota de edição de versão de Clientes
      path: "/register/sodexo/customers/version",
      exact: true,
      title: "Versão - Clientes",
      component: () => <VersionCustomers />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-cliente-update[1]"
      ),
    },

    {
      //Rota de criação de Clientes
      path: "/register/sodexo/customers/create",
      exact: true,
      title: "Criação - Clientes",
      component: () => <CreateCustomers />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-cliente-store[1]"
      ),
    },

    {
      //Rota de Exibição de 1 Cliente
      path: "/register/sodexo/customers/show",
      exact: true,
      title: "Exibição - Clientes",
      component: () => <DisplayCustomers />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "cadastro-cliente-show[1]"
      ),
    },

    {
      //Rota de Edição de 1 Cliente
      path: "/register/sodexo/customers/edit",
      exact: true,
      title: "Edição - Clientes",
      component: () => <EditCustomers />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission ===
          `cadastro-cliente-update[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Edição Certificado
      path: "/certificate-edit",
      exact: true,
      title: "Certificado Cliente",
      component: () => <EditCustomers idCadastro={user.cadastro_id} />,
      cadastro_tipo_id: [3],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission ===
          `cadastro-cliente-certificadodigitalnfe[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Preços de Combustível
      path: "/orders/price",
      exact: true,
      title: "Preços de Combustível",
      component: () => <ListOrders />,
      cadastro_tipo_id: [1, 2, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação de Preços de Combustível
      path: "/orders/price/create",
      exact: true,
      title: "Preços de Combustível",
      component: () => <CreateOrders />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-importar[${user.cadastro_tipo_id}]`
      ),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      //Rota de Preços de Combustível
      path: "/orders/price-fuel",
      exact: true,
      title: "Preços de Combustível",
      component: () => <ListPriceFuel />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação de Preços de Combustível
      path: "/orders/price-fuel/create",
      exact: true,
      title: "Preços de Combustível",
      component: () => <CreatePriceFuel />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-importar[${user.cadastro_tipo_id}]`
      ),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      //Rota de Preços de Frete
      path: "/orders/price-freight",
      exact: true,
      title: "Preços de Frete",
      component: () => <ListFreight />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação de Preços de fRETE
      path: "/orders/price-freight/create",
      exact: true,
      title: "Preços de Combustível",
      component: () => <CreateFreight />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-importar[${user.cadastro_tipo_id}]`
      ),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    {
      //Rota de Preços de Combustível
      path: "/orders/price-product",
      exact: true,
      title: "Preços de Produtos",
      component: () => <ListPriceProduct />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação de Preços de Combustível
      path: "/orders/price-product/create",
      exact: true,
      title: "Preços de Produtos",
      component: () => <CreatePriceProduct />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-importar[${user.cadastro_tipo_id}]`
      ),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      //Rota de Preços de Frete Produto
      path: "/orders/price-product-freight",
      exact: true,
      title: "Preços de Frete Produto",
      component: () => <ListProductFreight />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-list[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação de Preços de fRETE Produto
      path: "/orders/price-product-freight/create",
      exact: true,
      title: "Preços de Frete Produto",
      component: () => <CreateProductFreight />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) => p.namePermission === `preco-importar[${user.cadastro_tipo_id}]`
      ),
    },
    {
      //Rota de Exibição do Painel de Controle
      path: "/orders/painel",
      exact: true,
      title: "Painel de Controle - Cliente",
      component: () => <ListPainelCustomers />,
      cadastro_tipo_id: [3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "pedido-list[3]"
      ),
    },

    {
      //Rota de Exibição do Painel de Controle
      path: "/orders/painel",
      exact: true,
      title: "Painel de Controle - Distribuidor",
      component: () => <ListPainelDistributors />,
      cadastro_tipo_id: [2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "pedido-list[2]"
      ),
    },

    {
      //Rota de Exibição do Painel de Controle
      path: "/orders/painel",
      exact: true,
      title: "Painel de Controle - Pluxee",
      component: () => <ListPainel />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "pedido-list[1]"
      ),
    },

    {
      //Rota de Exibição do Painel de Controle
      path: "/orders/painel/v2",
      exact: true,
      title: "Painel de Controle - Pluxee V2",
      component: () => <ListPainelv2 />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "pedido-list[1]"
      ),
    },

    {
      //Rota de Exibição do Painel de Controle
      path: "/orders/painel/simulation",
      exact: true,
      title: "Simulação",
      component: () => <Simulation />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "pedido-list[1]"
      ),
    },

    {
      //Rota de Criação do Pedido no Painel de Controle
      path: "/orders/painel/new",
      exact: true,
      title: "Criação - Pedidos",
      component: () => <CreatePainelOrders />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `pedido-store[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação do Pedido no Painel de Controle
      path: "/orders/v2",
      exact: true,
      title: "Criação - Pedidos",
      component: () => <CreatePainelOrdersV2 />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `pedido-store[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Criação do Pedido no Painel de Controle
      path: "/orders/new",
      exact: true,
      title: "Criação - Pedidos",
      component: () => <CreatePainelOrders />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `pedido-store[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Edição do Pedido no Painel de Controle
      path: "/orders/painel/edit",
      exact: true,
      title: "Edição - Pedidos",
      component: () => <EditPainelOrders />,
      cadastro_tipo_id: [1, 2, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `pedido-update[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Visualização do Pedido no Painel de Controle
      path: "/orders/painel/show",
      exact: true,
      title: "Visualização - Pedidos",
      component: () => <DisplayPainelOrders />,
      cadastro_tipo_id: [1, 2, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `pedido-show[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Motivo de Reprovação dos Pedidos
      path: "/orders/painel/reprove",
      exact: true,
      title: "Motivos - Pedidos Reprovados",
      component: () => <ReprovePainelOrders statusPedido={"Reprovado"} />,
      cadastro_tipo_id: [1, 3],
    },

    {
      //Rota de Motivo de Reprovação dos Pedidos
      path: "/orders/painel/cancel",
      exact: true,
      title: "Motivos - Pedidos Cancelados",
      component: () => <ReprovePainelOrders statusPedido={"Cancelado"} />,
      cadastro_tipo_id: [1, 3],
    },

    {
      //Rota de Programar Novo Pedido no Painel de Controle
      path: "/orders/painel/program",
      exact: true,
      title: "Criação - Pedidos",
      component: () => <CreatePainelOrdersProgram />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission === `programacao-store[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Programar Novo Pedido pelo MenuSideBar
      path: "/orders/program",
      exact: true,
      title: "Programar Pedido",
      component: () => <CreatePainelOrdersProgram />,
      cadastro_tipo_id: [1, 3],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission === `programacao-store[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Histórico de Negociações
      path: "/orders/painel/history",
      exact: true,
      title: "Histórico - Pedidos",
      component: () => <OrdersPainelHistory />,
      cadastro_tipo_id: [1],
    },

    {
      //Rota de Relatórios de Pedidos Detalhados
      path: "/report/orders",
      exact: true,
      title: "Relatório - Pedidos Detalhados",
      component: () => <ReportOrders />,
      cadastro_tipo_id: [1, 2, 3],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission ===
          `relatorio-pedidosdetalhados[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Relatórios de Preço Médio de Combustiveis
      path: "/report/price-combustivel",
      exact: true,
      title: "Relatório - Preço Médio de Combustiveis",
      component: () => <ReportPriceCombustivel />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "relatorio-precomedio[1]"
      ),
    },

    {
      //Rota de Relatórios de Preço Médio de Combustiveis
      path: "/report/price-combustivel-billed",
      exact: true,
      title: "Relatório - Preço Médio Produto Faturado",
      component: () => <ReportPriceCombustivelFaturado />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "relatorio-precomedio[1]"
      ),
    },

    {
      //Rota de Relatórios de Condições Comerciais de Clientes
      path: "/report/customer-conditions",
      exact: true,
      title: "Relatório - Condições Comerciais de Clientes",
      component: () => <ReportCustomerConditions />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "relatorio-condicoescomerciaisclientes[1]"
      ),
    },

    {
      //Rota de Relatórios de Log
      path: "/report/log",
      exact: true,
      title: "Relatório - Log",
      component: () => <ReportLog />,
      cadastro_tipo_id: [1, 2, 3],
      permission: user?.permission.filter(
        (p) => p.namePermission === `relatorio-logs[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Relatórios de Registro de Clientes
      path: "/report/customer-register",
      exact: true,
      title: "Relatório - Registro de Clientes",
      component: () => <ReportCustomerRegister />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission ===
          `relatorio-cadastroclientes[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Relatórios de Registro de Distribuidores
      path: "/report/distributor-register",
      exact: true,
      title: "Relatório - Registro de Distribuidores",
      component: () => <ReportDistributorsRegister />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission ===
          `relatorio-cadastrodistribuidorestrrs[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Relatórios de Saving
      path: "/report/saving",
      exact: true,
      title: "Relatório - Saving",
      component: () => <ReportSaving />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "relatorio-saving[1]"
      ),
    },

    {
      //Rota de Relatórios de Reembolso de Distribuidor TRR
      path: "/report/refund",
      exact: true,
      title: "Relatório - Extrato Reembolso Distribuidor/TRR",
      component: () => <RefundDistributors />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission === `relatorio-reembolso[${user.cadastro_tipo_id}]`
      ),
    },

    {
      //Rota de Relatório Histórico de Negociações com Distribuidores/TRRs
      path: "/report/trading-history",
      exact: true,
      title: "Relatório Histórico de Negociações com Distribuidores/TRRs",
      component: () => <ReportTradingHistory />,
      cadastro_tipo_id: [1, 2],
      permission: user?.permission.filter(
        (p) =>
          p.namePermission === "relatorio-historiconegociacoes[1]" ||
          p.namePermission === "relatorio-historiconegociacoes[2]"
      ),
    },

    {
      //Rota de Criação de Comunicação
      path: "/communication/create",
      exact: true,
      title: "Comunicação - Criação",
      component: () => <CreateCommunication />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "comunicacao-store[1]"
      ),
    },

    {
      //Rota de Edição Comunicação
      path: "/communication/edit",
      exact: true,
      title: "Comunicação - Edição",
      component: () => <EditCommunication />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "comunicacao-update[1]"
      ),
    },

    {
      //Rota de Visualização Comunicação
      path: "/communication/show",
      exact: true,
      title: "Comunicação - Visualização",
      component: () => <DisplayCommunication />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "comunicacao-show[1]"
      ),
    },

    {
      //Rota de Lista Comunicação
      path: "/communication/list",
      exact: true,
      title: "Comunicação - Lista",
      component: () => <ListCommunication />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "comunicacao-list[1]"
      ),
    },

    {
      //Rota de Dashboard Sodexo
      path: "/dashboard",
      exact: true,
      title: "Dashboard- Pluxee",
      component: () => <DashboardSodexo />,
      cadastro_tipo_id: [1],
      permission: user?.permission.filter(
        (p) => p.namePermission === "dashboard-sodexo[1]"
      ),
    },

    {
      //Rota de Dashboard Distribuidor
      path: "/dashboard",
      exact: true,
      title: "Dashboard - Distribuidor",
      component: () => <DashboardDistributors />,
      cadastro_tipo_id: [2],
      permission: user?.permission.filter(
        (p) => p.namePermission === "dashboard-distribuidor[2]"
      ),
    },

    {
      //Rota de Dashboard Cliente
      path: "/dashboard",
      exact: true,
      title: "Dashboard - Cliente",
      component: () => <DashboardCustomers />,
      cadastro_tipo_id: [3],
      permission: user?.permission.filter(
        (p) => p.namePermission === "dashboard-cliente[3]"
      ),
    },

    {
      //Rota de Alteraç;ao de Senha
      path: "/change-password",
      exact: true,
      title: "Alterar Senha",
      component: () => <ChangePassword />,
      cadastro_tipo_id: [1, 2, 3],
    },
  ]);

  return (
    <RoutesContext.Provider value={{ routes }}>
      {children}
    </RoutesContext.Provider>
  );
};

export default RoutesContext;
