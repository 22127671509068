import styled from "styled-components";

export const Container = styled.div`
  /* width: 100%; */
  max-height: 100%;
  position: relative;
  /* width: 97%; */
  margin: auto;

  @keyframes fa-blink {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: #221C46;
    }
    100% {
      background-color: transparent;
    }
  }
  .blink {
    font-weight: bold;

    -webkit-animation: fa-blink 2.15s linear infinite;
    -moz-animation: fa-blink 2.15s linear infinite;
    -ms-animation: fa-blink 2.15s linear infinite;
    -o-animation: fa-blink 2.15s linear infinite;
    animation: fa-blink 2.15s linear infinite;
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /* padding: 0 2%; */
    padding-right: 3%;
    nav {
      display: flex;

            h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
        color: #221c46;
      }
    }

    div {
      display: flex;
      width: auto;

      align-items: center;
      justify-content: center;

      button {
        height: 30px;
        width: 30px;
        /* margin: 5px; */
        margin-left: 5px;
        margin-right: 5px;
            /* border-radius: 50%; */
        border: none;
        outline: none;
        color: #fff;
        transition: all 0.2s;
      }

      button:last-child {
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.4s;
        background-color: transparent;
        border-radius: 0;

        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  main {
    height: 70vh;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: scroll;

    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      background: #221c46;
    }

    table {

    .react-datepicker__input-container {
      input {
        background: #fff;
        font-size: 14px;
        padding: 10px;
        color: #000 #363636;
        font-weight: 500;
        width: 100% !important;
        /* height: 43px !important; */
        border: solid 1px #dee2e6;
      }

      input:focus {
        border: 2px solid #221c46;
      }
    }

    input[type='text'] {
      font-size: 11px;
      color: #767676;
      font-weight: 600;
      text-align: center;
    }

    .react-datepicker{
      border-radius: 0!important
    }

    .react-datepicker-wrapper {
      input {
        background: #fff;
        color: #000;
        font-size: 14px;
        padding: 10px;
        color: #363636;
        font-weight: 500;
        width: 100% !important;
        height: 20px !important;
        border: solid 1px #d9e0e7;
        border-radius: 5px;
        font-size: 11px !important;
      }
    }

    .react-datepicker__day--outside-month {
      color: #cfc1c1bd !important;
    }

    .react-datepicker__day--outside-month:hover {
      color: #544a4abd !important;
      /* pointer-events: none; */
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day{
      border-radius: 0!important;
      /* padding: 0px!important;
      line-height: none!important; */
      line-height: 30px!important;
      border: 1px solid #d7d7d7!important;
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today {
      background: #FF7375;
      color: #221c46;
      outline: none;
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
      background: #FF7375;
      color: #221c46!important;
    }

    .bnymVP main table .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover, .bnymVP main table .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus, .bnymVP main table .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today{
      color:#fff!important
    }

    .react-datepicker__day--in-range{
      background-color: #fff!important;
      background: #FF7375!important;
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
      background-color: #cfc1c1bd
     }

     .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: transparent;
     }

     .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
      background-color: #E0E0E4!important;
      color: #221c46!important
     }


      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      position: relative;
      overflow-x: scroll;
      overflow-y: auto;
      thead {
        z-index: 99999;
        th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          top: 0;
        }
        tr {
          button {
            background: #221c46;
            box-shadow: none!important;
            color: #fff;
            border: none!important;
            margin-right: 5px!important
          }
          th {
            padding: 5px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #221c46;
            color: #fff;
            /* z-index: 999; */
            text-align: center;
            border: 1px solid #dddddd;
          }

          //Tamanho das Colunas
          // Selecionar
          > :nth-child(1) {
            width: 75px;
          }
          // Ações
          > :nth-child(2) {
            width: 80px;
          }
          // Expandir
          > :nth-child(3) {
            width: 40px;
            svg {
              height: 22px;
              width: 22px;
            }
          }
          // N Pedido Sistema
          > :nth-child(4) {
            width: 80px;
          }
          // N Cotacao -> pedido staus
          > :nth-child(5) {
            width: 120px;
          }
          // Data
          > :nth-child(6) {
            width: 160px;
          }
          // Cliente
          > :nth-child(7) {
            width: 250px;
          }
          // Produtos
          > :nth-child(8) {
            width: 150px;
          }
          // Volume
          > :nth-child(9) {
            width: 80px;
          }
          // Preco
          > :nth-child(10) {
            width: 80px;
          }
          // total pedido
          > :nth-child(11) {
            width:100px;
          }
          // Distribuidor
          > :nth-child(12) {
            width: 250px;
          }
          // N cotacao incompleta
          > :nth-child(13) {
            width: 120px;
          }
          // N pedido cliente
          > :nth-child(14) {
            width: 100px;
            z-index: 9999!important;
          }
          // Carga Wizeo
          > :nth-child(15) {
            width: 100px;
          }
          // gerou programacao
          > :nth-child(16) {
            width: 120px;
          }
          // operador
          > :nth-child(17) {
            width: 130px;
          }
          // criado por
          > :nth-child(18) {
            width: 130px;
          }
          // Responsavel
          > :nth-child(19) {
            width: 130px;
          }
          // Prazo Pagamento
          > :nth-child(20) {
            width: 100px;
          }
          // Data entrega
          > :nth-child(21) {
            width: 160px;
          }
          // Status Pedido -> numero cotação
          > :nth-child(22) {
            width: 80px;
          }
          // Status Cotacao
          > :nth-child(23) {
            width: 120px;
          }
          // Integrado
          > :nth-child(24) {
            width: 120px;
          }
          // Nf serviço
          > :nth-child(25) {
            width: 120px;
          }
          // Nf distribuidor
          > :nth-child(26) {
            width: 120px;
          }
          // Espelho Cliente
          > :nth-child(27) {
            width: 120px;
          }
            // Espelho Distribuidor
            > :nth-child(28) {
            width: 120px;
          }
          // Boleto
          > :nth-child(29) {
            width: 120px;
          }
          // Histórico
          > :nth-child(30) {
            width: 100px;
          }
          // Ações
          > :nth-child(31) {
            width: 70px;
          }
        }
      }

      tbody {
        tr {
          color: #221c46;
          td {
            position: relative;
            border: 1px solid #dddddd;
            text-align: center;
            font-size: 11px;
            padding: 5px;
            word-break: break-word;

            > div {
              > input {
                border: 1px solid #dee2e6!important;
              }
              > input:focus {
                border: 2px solid #221c46!important;
              }

            }
          }

          .css-ql68v7-control {
                min-height: 43px !important;
              }


          td:first-child + td + td {
            svg {
              height: 22px;
              width: 22px;
            }
          }
        }

        tr {
          background-color: #FCECEA;
        }

        tr:disabled {
          background-color: grey;
        }

        tr:nth-child(even) {
          background-color: #fff;
        }
        tr:first-child {
          td {
            height: max-content;
            padding: 5px 0 5px 0;
            background-color: #FCECEA;
          }
        }
      }
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #221c46;

  width: 350px !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 999;

  button {
    height: 30px;
    width: 30px;
    margin: 28px;

        /* border-radius: 50%; */
    border: none;
    outline: none;
    color: #fff;
    transition: all 0.2s;
    display: inline-block;
  }

  label {
    display: inline-block;
    margin-right: 20px;
    margin-left: -15px;
    /* width: 40px; */
  }

  header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;

export const FormPagination = styled.div`
  display: flex;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  .bigButton {
    width: 65px !important;
  }

  button {
    width: 30px;
    height: 100%;
    outline: none;
    border: solid 0.25px;
    border-color: #cccccc;
    font-weight: 550;
    font-family: 16px;
    color: #797979;
    background-color: #fff;
    transition: all 0.4s;
    border-radius: 5px;

    :hover {
      background-color: #221c46;
      color: #f8f8f8;
      border: solid 0.5px;
      border-color: #221c46;
    }

    :disabled {
      background-color: #221c46;
      opacity: 0.6;
      color: #000;
      cursor: default;
    }
  }

  span {
    margin-left: 5px;
    margin-right: 10px;
  }
`;

export const ContainerPedidoLink = styled.div`
  a {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  svg {
    height: 14px;
    width: 14px;
    color: #221c46;
  }
`;

export const ContainerButton = styled.div`
  padding: 3px 0px;

  button {
    border-width: 0;
    color: #fff;
    /* background-color: #355ca5; */
    font-size: 12px;
    text-align: center;
    padding: 4px 15px 4px 15px;
    font-weight: bold;
    margin-right: 2px;
  }
`;


export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #2d353c;

  width: 450px !important;
  height: auto;
  max-height: 500px;

  border-radius: 10px;
  background-color: #fff;
  z-index: 999;

  > header {
    width: 100%;
    height: 45px;
    background-color: #2d353c;
    border-radius: 10px 10px 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }

  .react-datepicker__input-container {
  input {
    background: #fff;
    border: solid 1px #d9e0e7;
    border-color: #d9e0e7;
    color: #000;
    font-size: 14px;
    color: #000 #363636;
    font-weight: 500;
    width: 100% !important;
    height: 38px !important;
    border-radius: 5px;
    padding: 10px;
    outline: 0;
    margin-bottom: 10px;
  }
}


    .react-datepicker-wrapper {

      input {
        background: #fff;
        color: #000;
        font-size: 14px;
        padding: 10px;
        color: #363636;
        font-weight: 500;
        width: 100% !important;
        height: 20px !important;
        border: solid 1px #d9e0e7;
        border-radius: 5px;
        font-size: 11px !important;
      }
    }

    .react-datepicker__day--outside-month {
      color: #cfc1c1bd !important;
    }

    .react-datepicker__day--outside-month:hover {
      color: #544a4abd !important;
      /* pointer-events: none; */
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today {
      background: #f46666;
      color: #f2f4f5;
      outline: none;
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
      background: #cfc1c1bd;
    }

    .react-datepicker__day--in-range{
      background-color: #cfc1c1bd
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
      background-color: #cfc1c1bd
     }

     .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: transparent;
     }
`;

