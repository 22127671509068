import styled from "styled-components";

export const Container = styled.div`
  /* width: 100%; */
  max-height: 100%;
  position: relative;

  /* width: 97%; */
  margin: auto;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    /* padding: 0 2%; */
    padding-right: 3%;
    nav {
      display: flex;

            h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
        color: #221c46;
      }
    }

    div {
      display: flex;
      width: auto;

      align-items: center;
      justify-content: center;

      button {
        height: 30px;
        width: 30px;
        /* margin: 5px; */
        margin-left: 5px;
        margin-right: 5px;
            /* border-radius: 50%; */
        border: none;
        outline: none;
        color: #fff;
        transition: all 0.2s;
      }

      button:last-child {
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.4s;
        background-color: transparent;
        border-radius: 0;

        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  main {
    height: 70vh;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: scroll;

    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      background: #221c46;
    }
    table {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      position: relative;
      overflow-x: scroll;
      overflow-y: auto;
      thead {
        z-index: 99999;
        th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          top: 0;
        }
        tr {
          th {
            padding: 5px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #221c46;
            color: #fff;
            z-index: 999;
            text-align: center;
            border: 1px solid #dddddd;
          }

          //Tamanho das Colunas
                    // Ações
         > :nth-child(1) {
            width: 80px;
          }
          // N Pedido Sistema
          > :nth-child(2) {
            width: 80px;
          }

          // N Pedido Cliente
          > :nth-child(3) {
            width: 80px;
          }
          // Programado
          > :nth-child(4) {
            width: 120px;
          }
          // Data
          > :nth-child(5) {
            width: 100px;
          }
          // Criado Por
          > :nth-child(6) {
            width: 120px;
          }
          // Originado De
          > :nth-child(7) {
            width: 120px;
          }
          // Produtos
          > :nth-child(8) {
            width: 150px;
          }
          // Volume
          > :nth-child(9) {
            width: 100px;
          }
          // Distribuidor
          > :nth-child(10) {
            width: 220px;
          }
          // Prazo Pagamento
          > :nth-child(11) {
            width: 100px;
          }
          // Data entrega
          > :nth-child(12) {
            width: 100px;
          }
          // Status Pedido
          > :nth-child(13) {
            width: 150px;
          }
          // Nf serviço
          > :nth-child(14) {
            width: 120px;
          }
          // Nf distribuidor
          > :nth-child(15) {
            width: 120px;
          }
          // Boleto
          > :nth-child(16) {
            width: 120px;
          }
          // Ações
          > :nth-child(17) {
            width: 70px;
          }
        }
      }

      tbody {
        tr {
          td {
            position: relative;
            border: 1px solid #dddddd;
            text-align: center;
            font-size: 11px;
            padding: 5px;
            word-break: break-word;
          }
        }

        tr {
          background-color: #FCECEA;
        }

        tr:disabled {
          background-color: grey;
        }

        tr:nth-child(even) {
          background-color: #fff;
        }
        tr:first-child {
          td {
            height: max-content;
            padding: 0;
            padding-top: 10px;
            background-color: #FCECEA;
          }
        }
      }
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #221c46;

  width: auto !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 999;

  button {
    height: 30px;
    width: 30px;
    margin: 28px;

        /* border-radius: 50%; */
    border: none;
    outline: none;
    color: #fff;
    transition: all 0.2s;
    display: inline-block;
  }

  label {
    display: inline-block;
    margin-right: 35px;
    margin-left: -15px;
    width: 40px;
  }

  header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;
