import React from "react";
import Routes from "./routes";
import AppProvider from "./context";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { datadogRum } from '@datadog/browser-rum';


const App = () => {

  // datadogRum.init({
  //   applicationId: '65ad6def-f438-44df-bfde-ae0d07c85e42',
  //   clientToken: 'pub642c0f4a2893a76a57dd1224f3d0b49c',
  //   site: 'datadoghq.com',
  //   service: 'wizeo-trade-2.0',
  //   env: 'prod',
  //   sessionSampleRate: 100,
  //   sessionReplaySampleRate: 100,
  //   trackUserInteractions: true,
  //   trackResources: true,
  //   trackLongTasks: true,
  //   defaultPrivacyLevel: 'mask-user-input',
  // });

  return (
    <AppProvider>
      <DndProvider backend={HTML5Backend}>
        <Routes />
        <ToastContainer autoClose={3500} pauseOnHover={false} />
      </DndProvider>
    </AppProvider>
  );
};

export default App;
