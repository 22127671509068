import React, { createContext, useCallback, useEffect, useState } from "react";
import api from "./../services/api";
import axios from "axios";
import Spinner from "./../components/Spinner/Screen";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setLoading(true);
    const loadUserToken = localStorage.getItem("@wizeoTrade:token");

    if (loadUserToken) {
      // const token = JSON.parse(loadUserToken);
      //api().defaults.headers.Authorization = `Bearer ${token}`;
      // setUser(JSON.parse(loadUser));
    }

    async function getUser() {
      try {
        const { data: response } = await api.get("/usuario/get-data-user");

        const infoUser = {
          email: response.usuario.email,
          nome: response.usuario.name,
          user_id: response.usuario.id,
          cadastro_id: response?.cadastro[0].id,
          cadastro_tipo_id: response?.cadastro[0]?.cadastro_tipo_id,
          cnpj: response?.cadastro[0].cpf_cnpj,
          razao_social: response?.cadastro[0].razao_social,
          permission: response?.rolesUsuario,
          tipo_id: response?.usuario?.tipo_id,
          base_distribuicao: response?.cadastro[0]?.base_distribuicao,
          versao: response?.cadastro[0]?.versao_sistema
        };

        setUser(infoUser);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // localStorage.removeItem("@wizeoTrade:token");
      }
    }

    getUser();
  }, []);

  const signOut = useCallback(() => {
    api.put("/usuario/logout/" + user.user_id)
    localStorage.removeItem("@wizeoTrade:token");
    setUser(null);
  }, [user]);

  const signIn = useCallback(
    async (email, password, cadastro_tipo_id) => {
      const data = {
        // cnpj,
        email,
        password,
        cadastro_tipo_id,
      };

      // localStorage.removeItem("@wizeoTrade:token");

      const login = await api
        .post("/login", data, { cancelToken: source.token })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.error(
              "Erro ao tentar conectar no Trade, Verifique sua conexão e tente novamente."
            );
          }

          return error.response.data;
        });

      if (!!login.data) {
        setLoading(true);
        //Usuário Válido
        localStorage.setItem(
          "@wizeoTrade:token",
          JSON.stringify(login.data.token)
        );

        // api().defaults.headers.Authorization = `Bearer ${login.data.token}`;

        const { data: response } = await api.get("/usuario/get-data-user", {
          headers: {
            Authorization: `Bearer ${login.data.token}`,
          },
        });

        const infoUser = {
          email: response.usuario.email,
          nome: response.usuario.name,
          user_id: response.usuario.id,
          cadastro_id: response?.cadastro[0].id,
          cadastro_tipo_id: response?.cadastro[0]?.cadastro_tipo_id,
          cnpj: response?.cadastro[0].cpf_cnpj,
          razao_social: response?.cadastro[0].razao_social,
          permission: response?.rolesUsuario,
          tipo_id: response?.usuario?.tipo_id,
          base_distribuicao: response?.cadastro[0]?.base_distribuicao,
          versao: response?.cadastro[0]?.versao_sistema
        };

        setUser(infoUser);
        // document.location.reload();
        //setUser(login);
        setLoading(false);
      } else {
        setLoading(false);

        return login;
      }

      // return () => {
      //   source.cancel();
      // };
    },
    [source]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        loading,
        user,
        signIn,
        signOut,
        version: "v2.2.2.8",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
