import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import { Container, CreateForm, CreateFooter } from "./styles";

import ListSelect from "../../../../components/ListSelect";
import InputLabel from "../../../../components/InputLabel";
import Button from "../../../../components/ButtonDefault";
import api from "../../../../services/api";

export const DisplayBrand = ({ intl }) => {
  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const history = useHistory();

  useEffect(() => {

    if (!history.location.state?.id) {
      return history.push("/register/sodexo/tables/brands/list");
    }

    setStatus(history.location.state?.status === 1 ? "Ativo" : "Inativo");

    setData(history.location.state);
  }, [history]);

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  const nomePlaceholder = intl.formatMessage({ id: "nome" });
  const descricaoPlaceholder = intl.formatMessage({ id: "descricao" });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirMarca" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="codigo_externo"
            type="text"
            label={<FormattedMessage id="codigoExterno" />}
            value={data?.codigo_externo || ""}
            readOnly
          />

          <InputLabel
            name="nome"
            type="text"
            label={nomePlaceholder + " *"}
            value={data?.nome || ""}
            readOnly
          />
          <InputLabel
            name="descricao"
            type="text"
            label={descricaoPlaceholder + " *"}
            value={data?.descricao || ""}
            readOnly
          />

          <ListSelect
            isDisabled={true}
            labelPlaceholder={statusPlaceholder}
            value={{ value: status, label: status }}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayBrand);
