import axios from "axios";

var urlHost = window.location.hostname;
var url;

if (urlHost === "wizeotradev2.artiumsistemas.com.br") {
  // Homologue
  url = "https://apiwizeotrade.artiumsistemas.com.br/api/v1";
}

else if (urlHost === "qa.trade-v2.fuelandfleet.com") {
  // QA
  url = "http://qa.apitrade.fuelandfleet.com/api/v1" ;
}

else if (urlHost === "wizeotrade.fuelandfleet.com" || urlHost === "wizeotrade.com.br" || urlHost === "www.wizeotrade.com.br") {
  // Prod
  url = "https://apitrade.fuelandfleet.com/api/v1";
}

else if (urlHost === "localhost") {
  url = "https://apiwizeotrade.artiumsistemas.com.br/api/v1"; // Local
} else {
  /** here, always, in the URL, use 127.0.0.1 > in the browser it´s the same of localhost to laravel */
  url = "http://wizeotrade.test/api/v1"; // Test
}

const api = axios.create({
  baseURL: url,
  headers: {
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    Pragma: "no-cache",
    Expires: "0",
  },
});

const loadLanguage = localStorage.getItem("@wizeoTrade:lang");

if (loadLanguage) {
  api.defaults.headers.common["Accept-Language"] = loadLanguage?.replaceAll(
    '"',
    ""
  );
} else {
  api.defaults.headers.common["Accept-Language"] = "pt_BR";
}

if (localStorage.getItem("@wizeoTrade:token")) {
  api.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("@wizeoTrade:token")?.replaceAll('"', "");
}

export default api;
