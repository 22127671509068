function getErrorsApiValidation(errors) {
  const allErrors = errors?.response?.data;

  if (!allErrors) {
    return;
  }
  const objArray = Object.getOwnPropertyNames(errors.response.data);

  if (!objArray) {
    return;
  }

  const er = objArray.map((prefix) => {
    const obj = {};
    Object.assign(obj, {
      [prefix]: allErrors[prefix][0],
    });

    return obj;
  });

  const errorObj = {};
  er.forEach((item) => {
    Object.assign(errorObj, item);
  });

  return errorObj;
}

function getErrorsValidation(errors) {
  if (errors) {
    const validationErrors = {};

    errors.inner.forEach((error) => {
      validationErrors[error.path] = error.message;
    });
    return validationErrors;
  } else {
    return undefined;
  }
}

export { getErrorsApiValidation, getErrorsValidation };
