import React, { useCallback, useEffect, useState, createRef } from "react";
import { useTable } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import swal from "sweetalert";
import Alert from "./../../../assets/images/alert.png";

import {
  FiEdit,
  FiEye,
  FiTrash2,
  FiPlus,
  FiXCircle,
} from "react-icons/fi";
import { FormattedMessage, injectIntl } from "react-intl";
import iconFiltro from "./../../../assets/images/icone-filtro.png";
import { Container, FilterContainer } from "./styles";
import api from "./../../../services/api";

import ButtonIcon from "./../../../components/ButtonIcon";
import Button from "./../../../components/ButtonDefault";
import ButtonImage from "./../../../components/ButtonImage";
import iconVoltar from "./../../../assets/images/icone-voltar.svg";

import OptionsList from "./../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../components/OptionsList/OptionsIconPlaceholder";

import InputLabel from "./../../../components/InputLabel";

import ListSelect from "./../../../components/ListSelect";

import ContentSpinner from "./../../../components/Spinner/Content";

import ExcelLogo from "./../../../assets/images/logo-excel.png";

import Paginate from "./../../../components/Paginate";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ListCommunication = ({ intl }) => {
  const [communication, setCommunication] = useState([]);

  const [tipoComunicacaoFilter, setTipoComunicacaoFilter] = useState("");
  const [tipoFilter, setTipoFilter] = useState("");
  const [exibirFilter, setExibirFilter] = useState({
    value: "",
    label: <FormattedMessage id="todos" />,
  });
  const [dataDeFilter, setDataDeFilter] = useState("");
  const [dataAteFilter, setDataAteFilter] = useState("");
  const [status, setStatus] = useState({
    value: "s",
    label: <FormattedMessage id="ativo" />,
  });

  // Paginação
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const [loadingList, setLoadingList] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const refExcel = createRef(null);

  const exibiçãoOptions = [
    { value: "Banner", label: "Banner" },
    { value: "PopUp", label: "PopUp" },
  ];

  const tipoOptions = [
    { value: "Informativo", label: "Informativo" },
    { value: "Interativo", label: "Interativo" },
  ];

  const exibirOptions = [
    { value: 0, label: <FormattedMessage id="todos" /> },
    { value: 1, label: "Sodexo" },
    { value: 2, label: <FormattedMessage id="cliente" /> },
    { value: 3, label: <FormattedMessage id="distribuidores_trrs" /> },
  ];

  const statusOptions = [
    { value: "s", label: <FormattedMessage id="ativo" /> },
    { value: "n", label: <FormattedMessage id="inativo" /> },
  ];

  const desejaExcluirRegistro = intl.formatMessage({
    id: "desejaExcluirRegistro",
  });
  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });
  const todos = intl.formatMessage({ id: "todos" });

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        {
          value: item?.tipo_comunicacao || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.tipo || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.posicao || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.exibir_para || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.data_inicio || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.data_fim || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },

        {
          value: item?.status || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
      ];
    });

    return [
      {
        columns: [
          {
            title: "Exibição",
            width: { wpx: 130 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Exibir",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Posição",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Exibir para",
            width: { wpx: 140 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Data início",
            width: { wpx: 80 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Data fim",
            width: { wpx: 80 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Status",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await api.get("/comunicacao");

        const today = new Date();
        const formattedDate = format(today, "yyyy-MM-dd");

        const formattedList = response.data.map((item) => {
          const obj = {};

          var formattedstatus = "";
          if (item.data_fim < formattedDate) {
            formattedstatus = "Inativo";
          } else {
            formattedstatus = "Ativo";
          }

          Object.assign(obj, {
            id: item.id,
            tipo_comunicacao: item.tipo_comunicacao,
            tipo: item.tipo,
            posicao: item.posicao,
            exibir_clientes: item.exibir_clientes,
            exibir_sodexo: item.exibir_sodexo,
            exibir_distribuidor: item.exibir_distribuidor,
            data_inicio: format(parseISO(item.data_inicio), "dd/MM/yyyy"),
            data_fim: format(parseISO(item.data_fim), "dd/MM/yyyy"),
            status: formattedstatus,
            exibir_para:
              item.exibir_clientes +
                item.exibir_sodexo +
                item.exibir_distribuidor ===
              3
                ? todos
                : item.exibir_clientes +
                    item.exibir_sodexo +
                    item.exibir_distribuidor >
                  1
                ? "Múltiplos"
                : item.exibir_clientes === 1
                ? "Cliente"
                : item.exibir_sodexo === 1
                ? "Sodexo"
                : item.exibir_distribuidor === 1
                ? "Distribuidor/TRR"
                : "",
          });
          return obj;
        });

        setTotal(response?.total);
        setFirstPage(response?.first_page_url);
        setLastPage(response?.last_page);
        setLastPageUrl(response?.last_page_url);
        setNextPage(response?.next_page_url);
        setPrevPage(response?.prev_page_url);
        setCurrentPage(response?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setCommunication(formattedList);
        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
        return () => <h3>Erro ao carregar a lista</h3>;
      }
    })();
  }, [status, todos]);

  const handleCreate = useCallback(() => {
    history.push("/communication/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  const handleDisplay = useCallback(
    async (data) => {
      history.push("/communication/show", data);
    },
    [history]
  );

  const handleEdit = useCallback(
    (data) => {
      history.push("/communication/edit", data);
    },
    [history]
  );

  const handleDelete = useCallback(
    async (id) => {
      const willDelete = await swal({
        title: voceTemCerteza,
        text: desejaExcluirRegistro,
        icon: Alert,
        buttons: {
          cancel: {
            text: cancelar,
            value: null,
            visible: true,
            closeModal: true,
            className: "teste",
          },
          confirm: {
            text: opcaoSim,
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        dangerMode: true,
      });

      if (willDelete) {
        try {
          await api.delete(`/comunicacao/${id}`);
          setCommunication(
            communication.filter((comunicacao) => comunicacao.id !== id)
          );
          toast.success(<FormattedMessage id="registroExcluidoComSucesso" />);
        } catch (err) {
          toast.error(<FormattedMessage id="erroExcluirRegistro" />);
        }
      }
    },
    [communication, voceTemCerteza, desejaExcluirRegistro, opcaoSim, cancelar]
  );

  const handleFilter = useCallback(async () => {
    setLoadingButton(true);

    const checkedStatus = status?.value ? String(status?.value) : "";

    const checkedTipoComunicacao = !tipoComunicacaoFilter?.value
      ? ""
      : String(tipoComunicacaoFilter?.value);
    const checkedTipoFilter = !tipoFilter?.value
      ? ""
      : String(tipoFilter?.value);

    const checkedExibir = isNaN(exibirFilter?.value)
      ? ""
      : String(exibirFilter?.value);

    var exibir_sodexo = 1;
    var exibir_clientes = 1;
    var exibir_distribuidor = 1;

    if (parseInt(checkedExibir) === 1) {
      exibir_sodexo = 1;
      exibir_clientes = 0;
      exibir_distribuidor = 0;
    }

    if (parseInt(checkedExibir) === 2) {
      exibir_sodexo = 0;
      exibir_clientes = 1;
      exibir_distribuidor = 0;
    }

    if (parseInt(checkedExibir) === 3) {
      exibir_sodexo = 0;
      exibir_clientes = 0;
      exibir_distribuidor = 1;
    }

    try {
      const dataFilter = {
        exibir_sodexo,
        exibir_clientes,
        exibir_distribuidor,
        ativo_data: checkedStatus,
      };

      if (checkedTipoComunicacao !== "") {
        Object.assign(dataFilter, {
          tipo_comunicacao: checkedTipoComunicacao || "",
        });
      }

      if (checkedTipoFilter !== "") {
        Object.assign(dataFilter, { tipo: checkedTipoFilter || "" });
      }

      if (dataDeFilter !== "") {
        Object.assign(dataFilter, { data_inicio: dataDeFilter });
      }

      if (dataAteFilter !== "") {
        Object.assign(dataFilter, { data_fim: dataAteFilter });
      }

      const { data: response } = await api.post(
        "/comunicacao/filter",
        dataFilter
      );

      const today = new Date();
      const formattedDate = format(today, "yyyy-MM-dd");

      const formattedList = response.data.map((item) => {
        var formattedstatus = "";
        if (item.data_fim < formattedDate) {
          formattedstatus = "Inativo";
        } else {
          formattedstatus = "Ativo";
        }

        const obj = {};
        Object.assign(obj, {
          id: item.id,
          tipo_comunicacao: item.tipo_comunicacao,
          tipo: item.tipo,
          posicao: item.posicao,
          exibir_clientes: item.exibir_clientes,
          exibir_sodexo: item.exibir_sodexo,
          exibir_distribuidor: item.exibir_distribuidor,
          data_inicio: format(parseISO(item.data_inicio), "dd/MM/yyyy"),
          data_fim: format(parseISO(item.data_fim), "dd/MM/yyyy"),
          status: formattedstatus,
          exibir_para:
            item.exibir_clientes +
              item.exibir_sodexo +
              item.exibir_distribuidor ===
            3 ? (
              <FormattedMessage id="todos" />
            ) : item.exibir_clientes +
                item.exibir_sodexo +
                item.exibir_distribuidor >
              1 ? (
              "Múltiplos"
            ) : item.exibir_clientes === 1 ? (
              "Cliente"
            ) : item.exibir_sodexo === 1 ? (
              "Pluxee"
            ) : item.exibir_distribuidor === 1 ? (
              "Distribuidor/TRR"
            ) : (
              ""
            ),
        });

        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setCommunication(formattedList);
      setLoadingList(false);
      setLoadingButton(false);
      setShowFilter(false);
    } catch (error) {
      setLoadingList(false);
      setLoadingButton(false);
      setShowFilter(false);
    }
  }, [
    tipoComunicacaoFilter,
    tipoFilter,
    exibirFilter,
    status,
    dataDeFilter,
    dataAteFilter,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Exibição",
        accessor: "tipo_comunicacao", // accessor is the "key" in the data
      },
      {
        Header: <FormattedMessage id="exibir" />,
        accessor: "tipo",
      },
      {
        Header: <FormattedMessage id="posicao" />,
        accessor: "posicao",
      },
      {
        Header: <FormattedMessage id="exibirPara" />,
        accessor: "exibir_para",
      },
      {
        Header: <FormattedMessage id="dataInicio" />,
        accessor: "data_inicio",
      },
      {
        Header: <FormattedMessage id="dataFim" />,
        accessor: "data_fim",
      },
      {
        Header: <FormattedMessage id="status" />,
        accessor: "status",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: communication });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Paginação
  const handleUpdateList = useCallback(async (url) => {
    try {
      const { data: response } = await api.get(url);

      const today = new Date();
      const formattedDate = format(today, "yyyy-MM-dd");

      const formattedList = response.data.map((item) => {
        const obj = {};

        var formattedstatus = "";
        if (item.data_fim < formattedDate) {
          formattedstatus = "Inativo";
        } else {
          formattedstatus = "Ativo";
        }

        Object.assign(obj, {
          id: item.id,
          tipo_comunicacao: item.tipo_comunicacao,
          tipo: item.tipo,
          status: formattedstatus,
          posicao: item.posicao,
          exibir_clientes: item.exibir_clientes,
          exibir_sodexo: item.exibir_sodexo,
          exibir_distribuidor: item.exibir_distribuidor,
          data_inicio: format(parseISO(item.data_inicio), "dd/MM/yyyy"),
          data_fim: format(parseISO(item.data_fim), "dd/MM/yyyy"),
          exibir_para:
            item.exibir_clientes +
              item.exibir_sodexo +
              item.exibir_distribuidor ===
            3 ? (
              <FormattedMessage id="todos" />
            ) : item.exibir_clientes +
                item.exibir_sodexo +
                item.exibir_distribuidor >
              1 ? (
              "Múltiplos"
            ) : item.exibir_clientes === 1 ? (
              "Cliente"
            ) : item.exibir_sodexo === 1 ? (
              "Pluxee"
            ) : item.exibir_distribuidor === 1 ? (
              "Distribuidor/TRR"
            ) : (
              ""
            ),
        });
        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setCommunication(formattedList);
      setLoadingList(false);
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="comunicadoInformacoes" />
            </h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#221c46"}
              onClick={handleCreate}
              placeholder={<FormattedMessage id="incluir" />}
              permission="comunicacao-store[1]"
            />

            <ButtonImage
              ImageSrc={iconFiltro}
              backgroundColor={"#221c46"}
              color={"#ffffff"}
              placeholder={<FormattedMessage id="filtrar" />}
              onClick={() => setTimeout(() => setShowFilter(!showFilter), 150)}
            />

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Comunicação"
              >
                <ExcelSheet
                  dataSet={formatExcelData(communication)}
                  name="Comunicação"
                />
              </ExcelFile>

              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    rows.map((row) => {
                      // Prepare the row for display

                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <td {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render("Cell")
                                  }
                                </td>
                              );
                            })
                          }
                          <td>
                            <OptionsList>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEye}
                                  placeholder={<FormattedMessage id="exibir" />}
                                  onClick={() => handleDisplay(row.original.id)}
                                  permission="comunicacao-show[1]"
                                />
                              </li>

                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEdit}
                                  placeholder={<FormattedMessage id="editar" />}
                                  onClick={() => handleEdit(row.original.id)}
                                  permission="comunicacao-update[1]"
                                />
                              </li>
                              <li>
                                <OptionsIconPlaceholder
                                  placeholder={
                                    <FormattedMessage id="excluir" />
                                  }
                                  Icon={FiTrash2}
                                  onClick={() => handleDelete(row.original.id)}
                                  permission="comunicacao-destroy[1]"
                                />
                              </li>
                            </OptionsList>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              total={total}
            />
          </>
        )}
      </Container>
      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <ListSelect
              value={tipoComunicacaoFilter}
              onChange={(e) => setTipoComunicacaoFilter(e)}
              labelPlaceholder={<FormattedMessage id="exibicao" />}
              placeholder={<FormattedMessage id="selecionar" />}
              cacheOptions
              defaultOptions
              options={exibiçãoOptions}
              isClearable
            />

            <ListSelect
              value={tipoFilter}
              onChange={(e) => setTipoFilter(e)}
              labelPlaceholder={<FormattedMessage id="tipo" />}
              placeholder={<FormattedMessage id="selecionar" />}
              cacheOptions
              defaultOptions
              options={tipoOptions}
              isClearable
            />

            <ListSelect
              value={exibirFilter}
              onChange={(e) => setExibirFilter(e)}
              labelPlaceholder={<FormattedMessage id="exibirPara" />}
              placeholder={<FormattedMessage id="todos" />}
              cacheOptions
              defaultOptions
              options={exibirOptions}
              isClearable
            />

            <InputLabel
              name="date"
              label={<FormattedMessage id="dataInicio" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataDeFilter}
              onChange={(e) => setDataDeFilter(e.target.value)}
            />

            <InputLabel
              name="date"
              label={<FormattedMessage id="dataFim" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataAteFilter}
              onChange={(e) => setDataAteFilter(e.target.value)}
            />

            <ListSelect
              value={status}
              onChange={(e) => setStatus(e)}
              labelPlaceholder={"Status"}
              cacheOptions
              defaultOptions
              placeholder={<FormattedMessage id="selecionar" />}
              options={statusOptions}
              isClearable
            />
          </main>

          <footer>
            <Button
              type="button"
              themeType="default"
              onClick={handleFilter}
              loading={loadingButton}
            >
              <FormattedMessage id="filtrar" />
            </Button>
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListCommunication);
