import React, { useContext, useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../services/api";

import { Modal } from "react-bootstrap";
import ImageBanner from "../../components/ImageBanner";

import ListSelect from "./../../components/ListSelect";

import { getErrorsValidation } from "./../../utils/getErrorsValidation";

import Input from "./../../components/InputDefault";
import Button from "./../../components/ButtonDefault";

import Language from "./../../components/header/dropdown/language";
import BackgroundImage from "./../../assets/images/background-login.png";

import {
  Container,
  SignContainer,
  LogoContainer,
  Background,
  Separator,
  TagVersion,
} from "./styles";

import Logo from "./../../assets/images/pluxee-new.png";

import AuthContext from "./../../context/auth";
import UserLanguage from "./../../context/language";

const SignIn = ({ intl }) => {
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tipo, setTipo] = useState("");
  const [errors, setErrors] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [rememberToggle, setRememberToggle] = useState(false);

  const [modal, setModal] = useState(false);
  const [srcModal, setSrcModal] = useState("");
  const [linkModal, setLinkModal] = useState(null);

  const { signIn, version } = useContext(AuthContext);

  const { language } = useContext(UserLanguage);

  const passwordPlaceholder = intl.formatMessage({ id: "senha" });

  const tipoOptions = [
    { value: 1, label: "Backoffice"},
    { value: 3, label: <FormattedMessage id="cliente" /> },
    { value: 2, label: <FormattedMessage id="distribuidor" /> },
  ];

  useEffect(() => {
    const loadRememberData = localStorage.getItem("@wizeoTrade:remember");
    if (loadRememberData) {
      const formmatedData = JSON.parse(loadRememberData);

      setRememberToggle(true);
      setCnpj(formmatedData.cnpj);
      setEmail(formmatedData.email);
      setPassword(formmatedData.password);
      if (formmatedData.cadastro_tipo_id === 1){
        setTipo({ value: 1, label: <FormattedMessage id="Backoffice" /> })
      }
      if (formmatedData.cadastro_tipo_id === 2){
        setTipo({ value: 2, label: <FormattedMessage id="distribuidor" /> })
      }
      if (formmatedData.cadastro_tipo_id === 3){
        setTipo({ value: 3, label: <FormattedMessage id="cliente" /> })
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const dateFilter = moment().format("YYYY-MM-DD");

        const { data: response } = await api.post("/comunicacao/filter", {
          data_inicio_fim: dateFilter,
        });
        response.data.forEach((item) => {
          if (item.local === "Login") {
            setModal(true);
            setSrcModal(item.link_arquivo);
            if (item.link !== null) {
              setLinkModal(item.link);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      const cadastro_tipo_id = tipo.value;
      //e.preventDefault();
      setLoadingButton(true);

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .required(<FormattedMessage id="emailObrigatorio" />)
            .email(<FormattedMessage id="emailInvalido" />),
          password: Yup.string().min(
            6,
            <FormattedMessage id="minimoSeisParaSenha" />
          ),
        });

        await schema.validate(
          {email, password, cadastro_tipo_id},
          {
            abortEarly: false,
          }
        );

        const login = await signIn(email, password, cadastro_tipo_id);

        setLoadingButton(false);

        if (login) {
          /** aqui valida se o login retornou um erro */
          return toast.error(login[language]);
        }

        if (rememberToggle) {
          localStorage.setItem(
            "@wizeoTrade:remember",
            JSON.stringify({
              cnpj,
              email,
              password,
              cadastro_tipo_id: !cadastro_tipo_id ? "" && cnpj.length !== 0 : cadastro_tipo_id
            })
          );
        } else {
          localStorage.removeItem("@wizeoTrade:remember");
        }
      } catch (error) {
        console.log(error);
        toast.error(<FormattedMessage id="erroCredenciais" />);
        if (error?.name === "ValidationError") {
          setErrors(getErrorsValidation(error));
        }

        setLoadingButton(false);
      }
    },
    [tipo, email, password, cnpj, signIn, rememberToggle, language]
  );

  return (
    <>
      <Modal
        centered={true}
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <ImageBanner
            src={srcModal}
            height={500}
            width={"100%"}
            display={modal ? 0 : 1}
            link={linkModal !== null ? linkModal : null}
            style={{ margin: "-10px 0", padding: "5px 0px" }}
          />
        </Modal.Body>
      </Modal>

      <Container>
      <div className="rodape">
        <div className="grid">
            <div className="textLgpd">
              <a href="https://www.sodexobeneficios.com.br/?utm_source=site-institucional&utm_medium=rodape" target="_blank" rel="noreferrer">Benefícios e Incentivos</a>
            </div>
            <div className="textLgpd">
              <a href="https://sodexoservicos.com.br/contato" target="_blank" rel="noreferrer">Serviços Onsite</a>
            </div>
            <div className="textLgpd">
              <a href="https://br.sodexo.com/files/live/sites/com-br/files/Sobre/Politicas/Termos%20e%20Condi%c3%a7%c3%b5es%20Site%202021.pdf" target="_blank" rel="noreferrer">Termos de uso do Portal</a>
            </div>
            <div className="textLgpd">
              <a href="https://br.sodexo.com/sodexo-brasil/legal--privacidade/politica-de-privacidade.html" target="_blank" rel="noreferrer">Política de privacidade</a>
            </div>
            <div className="textLgpd">
              <a href="https://br.sodexo.com/home.html#" target="_blank" rel="noreferrer">Definições de cookies</a>
            </div>
            <div className="textLgpd">
              <a href="https://br.sodexo.com/files/live/sites/com-br/files/Impacto%20Positivo/Pol%c3%adtica%20de%20Cookies%2030112021%20(1).pdf" target="_blank" rel="noreferrer">Política de Cookies</a>
            </div>
        </div>
        <span className="copyright">
          <FormattedMessage id="copyright" />
          <TagVersion>{version}</TagVersion>
        </span>
      </div>

        <SignContainer>
          <img src={Logo} alt="Wizeo" />
          <form>
            <div className="container-form">
            <LogoContainer>
              {/* <img src={LogoWizeo} alt="Wizeo" />
              <img src={SodexoLogo} alt="Sodexo" /> */}
            </LogoContainer>
            <header>
              <h5>
                <FormattedMessage id="messageLogin" />
              </h5>
              <Language />
            </header>

            <ListSelect
              value={tipo}
              onChange={(e) => {
                if (e) {
                  setTipo(e)
                } else {
                  setTipo("");
                }
              }}
              placeholder={<FormattedMessage id="perfil" />}
              options={tipoOptions}
              geterror={errors?.cadastro_tipo_id}
              isClearable
            />

            {/* <Input
              placeholder="CNPJ"
              type="text"
              mask={MaskInput.CNPJ}
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value.replace(/[^\d]+/g, ""))}
              geterror={errors?.cnpj}
            /> */}

            <Input
              placeholder="E-mail"
              type="text"
              maxLength="80"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              geterror={errors?.email}
              autoComplete="on"
            />

            <Input
              placeholder={passwordPlaceholder}
              type="password"
              pattern="[a-zA-Z0-9]+"
              maxLength="20"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              geterror={errors?.password}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />

            <Button
              type="submit"
              loading={loadingButton}
              onClick={handleSubmit}
            >
              <FormattedMessage id="entrar" />
            </Button>

            <footer>
              <p>
                <FormattedMessage id="esqueceuSenha" />{" "}
                <Link to="/recovery-account">
                  {" "}
                  <FormattedMessage id="cliqueAqui" />
                </Link>
              </p>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  onChange={(e) => {
                    setRememberToggle(!rememberToggle);
                  }}
                  checked={rememberToggle}
                />
                <label className="custom-control-label" htmlFor="customSwitch1">
                  <FormattedMessage id="lembrarMe" />
                </label>
              </div>
            </footer>

            <Separator />


            </div>
          </form>
        </SignContainer>
        <Background>
          <img src={BackgroundImage} alt="img" />
        </Background>
      </Container>

    </>
  );
};

export default injectIntl(SignIn);
